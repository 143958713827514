import React, { useEffect,useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MainListItems from './listItems';
import Chart from './Chart';
import Orders from './Orders';
import History from './History';
import LogoutIcon from '@mui/icons-material/Logout';
import Notice from '../settings/Notice';
import Demo from '../master_settings/Demo';
import PropTypes from 'prop-types';
import { Tabs, Tab,ToggleButton, ToggleButtonGroup } from '@mui/material';
import ChartHistory from './ChartHistory';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard() {
  const [activeTab, setActiveTab] = React.useState(() => {
    // ローカルストレージから状態を取得する
    const storedActiveTab = localStorage.getItem('activeTab');
    return storedActiveTab ? parseInt(storedActiveTab) : 0; // 状態がない場合はデフォルト値を使用
  });

  const [activeTab2, setActiveTab2] = React.useState(() => {
    // ローカルストレージから状態を取得する
    const storedActiveTab2 = localStorage.getItem('activeTab2');
    return storedActiveTab2 ? parseInt(storedActiveTab2) : 0; // 状態がない場合はデフォルト値を使用
  });
  const [open, setOpen] = React.useState(true);
  const [buttonText, setButtonText] = React.useState(() => {
    // ローカルストレージから状態を取得する
    const storedButtonText = localStorage.getItem('buttonText');
    return storedButtonText || 'ホーム'; // 状態がない場合はデフォルト値を使用
  });
  const [tabHeaderText, setTabHeaderText] = React.useState(() => {
    // ローカルストレージから状態を取得する
    const storedTabHeaderText = localStorage.getItem('tabHeaderText');
    return storedTabHeaderText || '計器一覧'; // 状態がない場合はデフォルト値を使用
  });
  const [selectedMeterId, setSelectedMeterId] = React.useState("");

  const [selectedPlan, setSelectedPlan] = useState('demand');

  useEffect(() => {
    // 状態が更新されるたびにローカルストレージに保存する
    localStorage.setItem('tabHeaderText', tabHeaderText);
  }, [tabHeaderText]);

  useEffect(() => {
    // 状態が更新されるたびにローカルストレージに保存する
    localStorage.setItem('buttonText', buttonText);
  }, [buttonText]);

  useEffect(() => {
    // 状態が更新されるたびにローカルストレージに保存する
    localStorage.setItem('activeTab', activeTab.toString());
  }, [activeTab]);

  useEffect(() => {
    // 状態が更新されるたびにローカルストレージに保存する
    localStorage.setItem('activeTab2', activeTab2.toString());
  }, [activeTab2]);

  // Orders コンポーネント内で選択されたメーターの値を設定
  const handleMeterSelect = (meterId) => {
    setSelectedMeterId(meterId);
  };

  // ローカルストレージからデータを取得する関数
  const getDataFromLocalStorage = () => {
    const data = localStorage.getItem('userData');
    if(data){
      return JSON.parse(data)
    }else{
      // ログアウト後のリダイレクト
      window.location.href = '/'; // ログインページへのリダイレクト
    }
  };



  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // アクティブなタブに応じて tabHeaderText を更新
    switch (newValue) {
      case 0:
        setTabHeaderText('計器一覧');
        break;
      case 1:
        setTabHeaderText('GW一覧');
        break;
      case 2:
        setTabHeaderText('ユーザー一覧');
        break;
      // 必要に応じて他のケースも追加
      default:
        setTabHeaderText('計器一覧'); 
    }
  };

  const handleTabChange2 = (event, newValue) => {
    setActiveTab2(newValue);
    // アクティブなタブに応じて tabHeaderText を更新
    switch (newValue) {
      case 0:
        setButtonText('リアルタイム監視');
        break;
      case 1:
        setButtonText('過去デマンド');
        break;
      case 2:
        setButtonText('検針値履歴');
        break;
      // 必要に応じて他のケースも追加
      default:
        setButtonText('リアルタイム監視'); 
    }
  };
  
  // ローカルストレージからログイン情報取得
  const userData = getDataFromLocalStorage();
  const userName = userData ? userData.userName : '';
  const companyId = userData? userData.companyId : '';
  const userId = userData? userData.userId : '';
  const privilegeFlg = userData? userData.privilegeFlg : '';

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleDemoChart = (id,text) => {
    setSelectedMeterId(id);
    setActiveTab2(0);
    setButtonText(text);
  };

  const toggleChart = (id) => {
    setSelectedMeterId(id);
    setActiveTab2(0);
    setButtonText('リアルタイム監視');
  };

  const toggleHistory = (id) => {
    setSelectedMeterId(id);
    setActiveTab2(2);
    setButtonText('検針値履歴');
  };

  const handleLogout = () => {
    // ログアウトのロジックを実装
    // 例: ユーザーセッションをクリアして、ログインページにリダイレクト
    // セッションクリアの例 (実際のアプリに合わせて変更が必要)
    localStorage.removeItem('userData');
    localStorage.removeItem('buttonText');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('activeTab2');
    localStorage.removeItem('tabHeaderText');
    localStorage.removeItem('order_rows_per_page');
    localStorage.removeItem('history_rows_per_page');

    localStorage.removeItem('history_selectedPeriod');
    localStorage.removeItem('history_startDate');
    localStorage.removeItem('history_endDate');
    localStorage.removeItem('history_page');
    localStorage.removeItem('history_showTable');
    localStorage.removeItem('history_historyList');
    localStorage.removeItem('history_latestData');
    localStorage.removeItem('history_selectedMeterId');
    localStorage.removeItem('history_serialNo');
    localStorage.removeItem('history_edit_flg');
    
    // ログアウト後のリダイレクト
    window.location.href = '/'; // ログインページへのリダイレクト
  };

  

  const handleToggle = (event, newPlan) => {
    
    localStorage.removeItem('history_selectedPeriod');
    localStorage.removeItem('history_startDate');
    localStorage.removeItem('history_endDate');
    localStorage.removeItem('history_page');
    localStorage.removeItem('history_showTable');
    localStorage.removeItem('history_historyList');
    localStorage.removeItem('history_latestData');
    localStorage.removeItem('history_selectedMeterId');
    localStorage.removeItem('history_serialNo');
    localStorage.removeItem('history_edit_flg');
    
    console.log("newPlan:",newPlan);
    if (newPlan !== null) {
      setSelectedPlan(newPlan);
    }
    setButtonText('ホーム');
    setTabHeaderText('計器一覧');
    setActiveTab(0);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // 左右の余白を均等に配置
              pr: '24px', // 右側の余白を追加
              flexWrap: 'wrap', // フレックスアイテムを折り返す
            }}
          >
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {buttonText}
              </Typography>
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>              
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {`${userName} 様`}
              </Typography>
              <IconButton color="inherit" aria-label="Logout" onClick={handleLogout}>
                <LogoutIcon />
                <Typography variant="caption" sx={{ fontSize: 18 }}>ログアウト</Typography>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems 
              setButtonText={setButtonText}
              setActiveTab2={setActiveTab2}
              onMeterSelect={handleMeterSelect}
              privilegeFlg={privilegeFlg}
              selectedPlan={selectedPlan}
               />
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
          {privilegeFlg === 1 && (
            <>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="body1">
                  プラン切替
                </Typography>
                <ToggleButtonGroup
                  value={selectedPlan}
                  exclusive
                  onChange={handleToggle}
                  aria-label="Plan Selection"
                  sx={{
                    marginTop: 2, // プラン切替とToggleButtonGroupの間に余白を追加
                  }}
                >
                  <ToggleButton
                    value="demand"
                    aria-label="デマンドプラン"
                    color="primary"  // primary 色を適用
                    sx={{
                      maxWidth: 115, // ボタンの幅を指定して均等にする
                      backgroundColor: selectedPlan === 'demand' ? 'primary.main' : 'white', // 未選択時は白
                      color: selectedPlan === 'demand' ? 'white' : 'black',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.main', // 選択された時のテーマの primary 色
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'primary.dark', // 選択時にホバーすると暗めのprimary色
                        },
                      },
                      '&:hover': {
                        backgroundColor: selectedPlan === 'demand' ? 'primary.dark' : 'lightgrey', // ホバー時の色
                      },
                    }}
                  >
                    デマンドプラン
                  </ToggleButton>
                  
                  <ToggleButton
                    value="kensin"
                    aria-label="検針プラン"
                    color="primary"  // primary 色を適用
                    sx={{
                      minWidth: 115, // ボタンの幅を指定して均等にする
                      backgroundColor: selectedPlan === 'kensin' ? 'primary.main' : 'white', // 未選択時は白
                      color: selectedPlan === 'kensin' ? 'white' : 'black',
                      '&.Mui-selected': {
                        backgroundColor: 'primary.main', // 選択された時のテーマの primary 色
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'primary.dark', // 選択時にホバーすると暗めのprimary色
                        },
                      },
                      '&:hover': {
                        backgroundColor: selectedPlan === 'kensin' ? 'primary.dark' : 'lightgrey', // ホバー時の色
                      },
                    }}
                  >
                    検針プラン
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </>
          )}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {buttonText === 'ホーム' && privilegeFlg === 1 && selectedPlan === 'demand' && (
            <>
              <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  labelColor="primary"
                  variant="scrollable"
                  sx={{
                    backgroundColor: '#333333',
                  }}
                >
                  <Tab
                    label="計器一覧"
                    sx={{
                      // ボタンの色
                      backgroundColor: '#CCCCCC', // 明るいグレー
                      // ラベルの色
                      '& .MuiTab-label': {
                        color: '#000000', // 黒
                      },
                      // 枠線のスタイル
                      border: '1px solid #000000',
                      borderRadius: '5px', // 枠線の角を丸くする場合は追加
                      // タブボタンの間にスペースを空けるスタイル
                      marginRight: '15px',
                      // タブボタンの間にスペースを空けるスタイル
                      marginLeft: '15px',
                    }}
                  />
                  <Tab
                    label="GW一覧"
                    visible="false"
                    sx={{
                      // ボタンの色
                      backgroundColor: '#CCCCCC', // 明るいグレー
                      // ラベルの色
                      '& .MuiTab-label': {
                        color: '#000000', // 黒
                      },
                      // 枠線のスタイル
                      border: '1px solid #000000',
                      borderRadius: '5px', // 枠線の角を丸くする場合は追加
                      // タブボタンの間にスペースを空けるスタイル
                      marginRight: '15px',
                    }}
                  />
                  <Tab
                    label="ユーザー一覧"
                    sx={{
                      // ボタンの色
                      backgroundColor: '#CCCCCC', // 明るいグレー
                      // ラベルの色
                      '& .MuiTab-label': {
                        color: '#000000', // 黒
                      },
                      // 枠線のスタイル
                      border: '1px solid #000000',
                      borderRadius: '5px', // 枠線の角を丸くする場合は追加
                    }}
                  />
                </Tabs>
              </>
            )}

            {(buttonText === 'リアルタイム監視' || buttonText === '過去デマンド' || buttonText === '検針値履歴' ) && (privilegeFlg === 0 ||privilegeFlg === 1) && selectedPlan === 'demand' &&  (
            <>
              <Tabs
                  value={activeTab2}
                  onChange={handleTabChange2}
                  indicatorColor="primary"
                  labelColor="primary"
                  variant="scrollable"
                  sx={{
                    backgroundColor: '#333333',
                  }}
                >
                  <Tab
                    label="リアルタイム監視"
                    sx={{
                      // ボタンの色
                      backgroundColor: '#CCCCCC', // 明るいグレー
                      // ラベルの色
                      '& .MuiTab-label': {
                        color: '#000000', // 黒
                      },
                      // 枠線のスタイル
                      border: '1px solid #000000',
                      borderRadius: '5px', // 枠線の角を丸くする場合は追加
                      // タブボタンの間にスペースを空けるスタイル
                      marginRight: '15px',
                      // タブボタンの間にスペースを空けるスタイル
                      marginLeft: '15px',
                    }}
                  />
                  <Tab
                    label="過去デマンド"
                    sx={{
                      // ボタンの色
                      backgroundColor: '#CCCCCC', // 明るいグレー
                      // ラベルの色
                      '& .MuiTab-label': {
                        color: '#000000', // 黒
                      },
                      // 枠線のスタイル
                      border: '1px solid #000000',
                      borderRadius: '5px', // 枠線の角を丸くする場合は追加
                      // タブボタンの間にスペースを空けるスタイル
                      marginRight: '15px',
                      
                    }}
                  />
                  <Tab
                    label="検針値履歴"
                    visible="false"
                    sx={{
                      // ボタンの色
                      backgroundColor: '#CCCCCC', // 明るいグレー
                      // ラベルの色
                      '& .MuiTab-label': {
                        color: '#000000', // 黒
                      },
                      // 枠線のスタイル
                      border: '1px solid #000000',
                      borderRadius: '5px', // 枠線の角を丸くする場合は追加
                      // タブボタンの間にスペースを空けるスタイル
                      marginRight: '15px',
                    }}
                  />
                </Tabs>
              </>
            )}
          <Container maxWidth='100%' sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {(buttonText ==='リアルタイム監視') &&(
                  <Paper 
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <Chart 
                      meter_id={selectedMeterId} 
                      company_id={companyId} 
                      privilegeFlg={privilegeFlg} 
                      onMeterSelect={handleMeterSelect} 
                      setButtonText={setButtonText}
                    />
                  </Paper>
                )}
              </Grid>

              <Grid item xs={12}>
                {(buttonText ==='過去デマンド') &&(
                  <Paper 
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <ChartHistory 
                      meter_id={selectedMeterId} 
                      company_id={companyId} 
                      privilegeFlg={privilegeFlg} 
                      onMeterSelect={handleMeterSelect} 
                    />
                  </Paper>
                )}
              </Grid>
              
              <Grid item xs={12}>
                {buttonText === 'ホーム' &&(
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' , width: '100%'}}>
                    <Orders 
                      onChartClick={toggleChart}
                      onHistoryClick={toggleHistory}
                      title={tabHeaderText}
                      param={companyId}
                      login_user_id={userId}
                      privilegeFlg={privilegeFlg}
                      selectedPlan={selectedPlan}
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12}>
                {/* Historyコンポーネントの表示 */}
                {buttonText ==='検針値履歴' &&(
                  <Paper sx={{ 
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}>
                    <History 
                      meter_id={selectedMeterId}
                      company_id={companyId}
                      privilegeFlg={privilegeFlg}
                      onMeterSelect={handleMeterSelect}
                      selectedPlan={selectedPlan}
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12}>
                {buttonText ==='設定' &&(
                  <Paper sx={{ 
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}>
                    <Notice
                      companyId={companyId}
                      login_user_id={userId}
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12}>
                {buttonText ==='管理者メニュー' &&(
                  <Paper sx={{ 
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      <Demo 
                        onDemoClick={toggleDemoChart}
                      />
                    </Paper>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

Dashboard.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      userName: PropTypes.string,
      companyId: PropTypes.string,
    }),
  }),
};