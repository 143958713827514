import React, { useState,useEffect,useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button'; // ボタンコンポーネントをインポート
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types'; // prop-typesパッケージからPropTypesをインポート
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiEndpointHistoryStart = process.env.REACT_APP_API_ENDPOINT_HISTORY + '/exec';
const apiEndpointHistoryResult = apiEndpointHistoryStart + '/result';
const stepFunctionsMachine = process.env.REACT_APP_STEP_FUNCTIONS_STATE_MACHINE;

const latest_data_header = [
  {label:'現在時刻設定',minWidth:150},
  {label:'現在年月日設定',minWidth:150},
  {label:'動作状態',minWidth:150},
  {label:'係数',minWidth:150},
  {label:'係数の倍率',minWidth:150},
  {label:'確定日',minWidth:150},
  {label:'積算有効電力量有効桁数',minWidth:150},
  {label:'積算有効電力量単位',minWidth:150},
  {label:'需要電力有効桁数',minWidth:150},
  {label:'需要電力単位',minWidth:150},
];

const getCellContent = (header, row) => {
  switch (header) {
    case '現在時刻設定':
      return row.CURRENT_TIME_SETTING;
    case '現在年月日設定':
      return row.CURRENT_DATE_SETTING;
    case '動作状態':
      return row.OPERATING_STATUS;      
    case '係数':
      return row.COEFFICIENT;
    case '係数の倍率':
      return row.COEFFICIENT_MAGNIFICATION;
    case '確定日':
      return row.CONFIRMATION_DATE;
    case '積算有効電力量有効桁数':
      return row.VALID_POWER_DIGITS;
    case '積算有効電力量単位':
      return row.VALID_POWER_UNIT;
    case '需要電力有効桁数':
      return row.SIGNIFICANT_DIGITS_OF_DEMAND_POWER;
    case '需要電力単位':
      return row.UNIT_OF_DEMAND_POWER;
    default:
      return '';
  }
};

// 日付のフォーマットを変更するヘルパー関数
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const fetchDataFromServer = async (company_id,privilegeFlg,selectedPlan) => {
  try {
    
    const info_flg = '0';
    if(!privilegeFlg){
      privilegeFlg = '0';
    }

    const requestData = {
      path: '/get_info',
      body: JSON.stringify({
        company_id: company_id,
        info_flg: info_flg,
        privilege_flg:privilegeFlg,
        chart_flg:'0',
        selected_plan:selectedPlan,
      }),
    };

    const response = await axios.post(apiEndpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.data && response.data.statusCode === 200) {
      return response.data.body; // レスポンスのデータを返す
    } else {
      alert('Error in API response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return null;
  }
};


export default function History({meter_id,company_id,privilegeFlg,onMeterSelect,selectedPlan}) {
  const inputStyle = {
    height: '40px',
    width: '150px',
    fontSize: '16px',
  };

  // 日付セット
  const currentDate = new Date();
  const lastMonthDate = new Date();
  lastMonthDate.setMonth(currentDate.getMonth() - 1);
  lastMonthDate.setDate(1);

  const formattedLastMonthDate = formatDate(lastMonthDate);
  const formattedCurrentDate = formatDate(currentDate);

   // ローカルストレージから状態を読み込む関数
   const getSavedState = (key, defaultValue) => {
    const saved = localStorage.getItem(key);
    return saved !== null ? JSON.parse(saved) : defaultValue;
  };

  const claerLocalStorage = () =>{
    localStorage.removeItem('history_selectedPeriod');
    localStorage.removeItem('history_startDate');
    localStorage.removeItem('history_endDate');
    localStorage.removeItem('history_page');
    localStorage.removeItem('history_showTable');
    localStorage.removeItem('history_historyList');
    localStorage.removeItem('history_latestData');
    localStorage.removeItem('history_selectedMeterId');
    localStorage.removeItem('history_serialNo');
    localStorage.removeItem('history_edit_flg');

    setShowTable(false);
  }

  // 初回レンダリングをスキップするためのフラグを追加
  const isFirstRender = useRef(true);

  // ラジオボタンの選択状態を管理するステート
  // 状態管理
  const [selectedPeriod, setSelectedPeriod] = useState(() =>
    getSavedState('history_selectedPeriod', '1')
  );
  const [startDate, setStartDate] = useState(() => getSavedState('history_startDate', formattedLastMonthDate));
  const [endDate, setEndDate] = useState(() => getSavedState('history_endDate', formattedCurrentDate));
  const [page, setPage] = useState(() => getSavedState('history_page', 0));
  const [rowsPerPage, setRowsPerPage] = useState(() =>
    getSavedState('history_rows_per_page', parseInt(localStorage.getItem('history_rows_per_page')) || 10)
  );
  const [showTable, setShowTable] = useState(() => getSavedState('history_showTable', false));
  const [meter_list, setMeterList] = useState([]);
  const [history_list, setHistoryList] = useState(() => getSavedState('history_historyList', []));
  const [latest_data, setLatestData] = useState(() => getSavedState('history_latestData', ''));
  const [selectedMeterId, setSelectedMeterId] = useState(() => getSavedState('history_selectedMeterId', ''));
  const [edit_flg, setEditFlg] = useState(()=>
    getSavedState('history_edit_flg',false)
  );
  const [serial_no, setSerialNo] = useState(() => getSavedState('history_serialNo', ''));
  const buttonProcessing = useRef(false);
  const [isLoading, setIsLoading] = useState(true); // 読み込み中の状態を管理

  // useEffect を使って状態をローカルストレージに保存
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    localStorage.setItem('history_selectedPeriod', JSON.stringify(selectedPeriod));
    localStorage.setItem('history_startDate', JSON.stringify(startDate));
    localStorage.setItem('history_endDate', JSON.stringify(endDate));
    localStorage.setItem('history_page', JSON.stringify(page));
    localStorage.setItem('history_rows_per_page', JSON.stringify(rowsPerPage));
    localStorage.setItem('history_showTable', JSON.stringify(showTable));
    localStorage.setItem('history_edit_flg', JSON.stringify(edit_flg));
    localStorage.setItem('history_historyList', JSON.stringify(history_list));
    localStorage.setItem('history_latestData', JSON.stringify(latest_data));
    localStorage.setItem('history_selectedMeterId', JSON.stringify(selectedMeterId));
    localStorage.setItem('history_serialNo', JSON.stringify(serial_no));
  }, [selectedPeriod, startDate, endDate, page, rowsPerPage, showTable, history_list, latest_data, selectedMeterId, serial_no]);

  useEffect(() => {

    //メーターリスト構築
    fetchData();

    if(meter_id){
      claerLocalStorage();
      setSelectedPeriod('1');
      setEditFlg(false);
      // 検索
      handleSearchClick(meter_id);
    }

  }, []);

  const fetchData = async () => {
    // ローカルストレージに保存されていない変数が一つでもある場合かつ privilegeFlg が 2 または selectedPlan が 'kensin' の場合
    const historySelectedPeriod = localStorage.getItem('history_selectedPeriod');
    const historyStartDate = localStorage.getItem('history_startDate');
    const historyEndDate = localStorage.getItem('history_endDate');
    const historyShowTable = localStorage.getItem('history_showTable');
    const historyHistoryList = localStorage.getItem('history_historyList');
    const historySelectedMeterId = localStorage.getItem('history_selectedMeterId');
    const historySerialNo = localStorage.getItem('history_serialNo');
    
    try {
      setIsLoading(true); // 読み込み中の状態を true に設定
      const data = await fetchDataFromServer(company_id,privilegeFlg,selectedPlan);

      if (data) {
        const dataArray = JSON.parse(data);
        setMeterList(dataArray);
        if(meter_id){
          const currentIndex = dataArray.findIndex((meter) => meter.METER_ID === meter_id);
          const newSeerialNo = dataArray[currentIndex].SERIAL_NO;
          setSerialNo(newSeerialNo);

          setSelectedMeterId(meter_id);
          onMeterSelect(meter_id);
        }else{
          console.log("historySelectedMeterId:",historySelectedMeterId);
          console.log("historySerialNo:",historySerialNo);
          console.log("meter_id:",meter_id);

          if(historySerialNo === null){
            setSerialNo(dataArray[0].SERIAL_NO);
          }
          
          if(historySelectedMeterId === null){
            setSelectedMeterId(dataArray[0].METER_ID);
            onMeterSelect(dataArray[0].METER_ID);
          }
          
          // ローカルストレージの各値がnullでない場合
          if (historySelectedPeriod === null && historyStartDate === null && historyEndDate === null &&
            historyShowTable === null && historyHistoryList === null && historySelectedMeterId === null && historySerialNo === null) {
              handleSearchClick(dataArray[0].METER_ID);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
    if (meter_id) {
      // 計器一覧の詳細ボタンを押下した場合
      setIsLoading(true); // 読み込み中の状態を true に設定
    }else{
      if (historySelectedPeriod !== null || historyStartDate !== null || historyEndDate !== null ||
        historyShowTable !== null || historyHistoryList !== null || historySelectedMeterId !== null || historySerialNo !== null) {
          setIsLoading(false); // 読み込み中の状態を true に設定
      }
    }
  };

  const handlePrevClick = async () => {
    if (buttonProcessing.current) return; // 送信処理中の場合は処理を中断する
    buttonProcessing.current = true; // 送信処理中フラグを立てる
    
    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId);
  
    // 現在の選択が最初の選択でない場合
    if (currentIndex > 0) {
      const newSelectedMeterId = meter_list[currentIndex - 1].METER_ID;

      setSelectedMeterId(newSelectedMeterId);
      onMeterSelect(newSelectedMeterId);

      const newSeerialNo = meter_list[currentIndex - 1].SERIAL_NO;
      setSerialNo(newSeerialNo);
      
    }
    setHistoryList([]);
    setLatestData(null);
    setShowTable(false);
    
    buttonProcessing.current = false;
    
  };

  const handleNextClick = async () => {
    if (buttonProcessing.current) return; // 送信処理中の場合は処理を中断する
    buttonProcessing.current = true; // 送信処理中フラグを立てる

    const currentIndex = meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId);
  
    // 現在の選択が最初の選択でない場合
    if (currentIndex < meter_list.length - 1) {
      const newSelectedMeterId = meter_list[currentIndex + 1].METER_ID;

      setSelectedMeterId(newSelectedMeterId);
      onMeterSelect(newSelectedMeterId);

      const newSeerialNo = meter_list[currentIndex + 1].SERIAL_NO;
      setSerialNo(newSeerialNo);
    }

    setHistoryList([]);
    setLatestData(null);
    setShowTable(false);
    buttonProcessing.current = false;
  
  };

  const handleChangeMeterId = (event) => {
    setSelectedMeterId(meter_list[event.target.selectedIndex].METER_ID);
    onMeterSelect(meter_list[event.target.selectedIndex].METER_ID);
    setSerialNo(meter_list[event.target.selectedIndex].SERIAL_NO);
    setHistoryList([]);
    setLatestData(null);
    setShowTable(false);
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
    setHistoryList([]);
    setLatestData(null);
    setShowTable(false);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
    setHistoryList([]);
    setLatestData(null);
    setShowTable(false);
  };

  const togglePeriod = (event) => {
    setSelectedPeriod(event.target.value === '0' ? '0' : '1');
    setEditFlg(event.target.value === '0' ? true : false);
    setHistoryList([]);
    setLatestData(null);
    setShowTable(false);
  };

  // ページ変更時のハンドラ関数
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // 1ページあたりの行数変更時のハンドラ関数
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('history_rows_per_page', parseInt(event.target.value, 10));
    setPage(0); // 表示ページをリセット
  };

  // 検索ボタンがクリックされたときにテーブルを表示
  const handleSearchClick = async (meter_id) => {
    setIsLoading(true); // 読み込み中の状態を true に設定
    let req_selected_period = '0';
    if(meter_id){
      req_selected_period = '1';
    }else{
      req_selected_period = selectedPeriod;
    }
    console.log('req_selected_period:',req_selected_period);
    if(req_selected_period === '1'){
      if(!startDate || !endDate){
        alert("開始日または終了日を入力してください")
        return;
      }

      if(startDate > endDate){
        alert("開始日は終了日の後にしてください")
        return;
      }

    }
    
    let req_meter_id;
    if(meter_id){
      req_meter_id = meter_id;
    }else{
      if(!selectedMeterId){
        if (!meter_list || meter_list.length === 0) {
          alert("メーターリストが取得されていません");
          return;
        }
        req_meter_id = meter_list[0].METER_ID
      }else{
        req_meter_id = selectedMeterId
      }
    }

    // ランダムな10桁の半角英数字を生成する関数
    function generateRandomString() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    try {
      const input = {
        path: '/get_history',
        body: JSON.stringify({
          meter_id: req_meter_id,
          search_flg:req_selected_period,
          start_date:startDate,
          end_date:endDate,
        }),
      };

      const requestData = {
        input: JSON.stringify(input),
        name: generateRandomString(), // ランダムな10桁の半角英数字を生成して設定
        stateMachineArn: stepFunctionsMachine
      };

      // Step Functions(実行処理)
      const response = await axios.post(apiEndpointHistoryStart, requestData);
      if (response && response.data && response.status === 200) {
        
        const data = response.data;
        if (data) {
          const executionArn = data.executionArn;
          const resultRequestData = {
            executionArn : executionArn,
          }

          let result_status = 'RUNNING';
          let result_data;

          // Step Functions の実行が完了するまでループ
          while (result_status === 'RUNNING') {
            // Step Functions の結果を確認
            const response_result = await axios.post(apiEndpointHistoryResult, resultRequestData);

            // レスポンスが成功した場合
            if (response_result && response_result.data && response_result.status === 200) {
              result_data = response_result.data;
              console.log('result_data:',result_data);

              // 結果のステータスを取得
              result_status = result_data.status;

              // ステータスが "RUNNING" の場合、再度 API を呼び出す
              if (result_status === 'RUNNING') {
                console.log('Step Functions がまだ実行中です。再度確認します。');
              }
            } else {
              console.error('Step Functions の結果の取得中にエラーが発生しました。');
              break; // エラーが発生した場合、ループを終了する
            }

            // 結果のステータスが "RUNNING" でなければループを終了する
            if (result_status === 'SUCCEEDED') {
              break;
            }

            // 1秒待機してから再度ループを実行
            await new Promise(resolve => setTimeout(resolve, 1000));
          }

          const output = JSON.parse(result_data.output);
          console.log('output:',output);

          if(output.statusCode === 200){
            // Pre-Signed URL を取得
            const preSignedUrl = JSON.parse(output.body).url;

            console.log('url:', preSignedUrl);

            // S3 へのリクエストを行う
            const s3Response = await axios.get(preSignedUrl);

            // リクエストが成功した場合の処理
            console.log('Upload successful:', s3Response);

            if(s3Response){
              setHistoryList(s3Response.data.history_data);
              setLatestData(s3Response.data.latest_data[0]);

              setPage(0); // 表示ページをリセット
              setShowTable(true);
            }
          }else{
            console.log("エラーが発生しました。 エラー内容:" ,output);
            const body = JSON.parse(output.body);
            alert("エラーが発生しました。 エラー内容:" + body.message);
            claerLocalStorage();
          }

          

        }
      // データの再読み込みなど、更新後の処理を追加することができます
      } else {
        console.log("エラーが発生しました。 エラー内容:" ,response);
        const body = response.data;
        alert("エラーが発生しました。 エラー内容:" + body.message);
        claerLocalStorage();
      }
    } catch (error) {
      console.log('Error calling Lambda function:',error);
      // その他のエラーの場合
      alert('Error calling Lambda function:'+ error.message);
      claerLocalStorage();
      
    }finally {
      setIsLoading(false); // 非同期処理の完了後に setIsLoading(false) を呼び出す
    }
    
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  const handleCSVExport = () => {
    // CSVデータを作成し、ユーザーのダウンロード処理などを実装します
    // このコードはCSVデータを作成するイメージです
    let csvData = '検針値履歴\n';

    latest_data_header.forEach((latest_header, index) => {
      csvData += latest_header.label;
      // 最後の要素でなければカンマを追加
      if (index !== latest_data_header.length - 1) {
          csvData += ',';
      }
  });
    // 改行
    csvData += '\n'

    csvData += `${latest_data.CURRENT_TIME_SETTING},${latest_data.CURRENT_DATE_SETTING},${latest_data.OPERATING_STATUS},${latest_data.COEFFICIENT},${latest_data.COEFFICIENT_MAGNIFICATION},${latest_data.CONFIRMATION_DATE},${latest_data.VALID_POWER_DIGITS},${latest_data.VALID_POWER_UNIT},${latest_data.SIGNIFICANT_DIGITS_OF_DEMAND_POWER},${latest_data.UNIT_OF_DEMAND_POWER}\n`;

    // 改行
    csvData += '\n'

    // 30分値
    csvData += '30分値（電力量 or 電力 ＝ 指針値 ×係数×係数の倍率×単位）\n'
    csvData += 'メーター管理番号,取得日時,定時積算有効電力量計測値,定時需要電力(指針値),定時需要電力(kW),月間最大需要電力(指針値),月間最大需要電力(kW)\n';
    history_list.forEach(row => {
      const cycleActiveEnergy = row.CYCLE_ACTIVE_ENERGY !== -1 ? row.CYCLE_ACTIVE_ENERGY : '';
      const cycleDemandPower = row.CYCLE_DEMAND_POWER !== -1 ? row.CYCLE_DEMAND_POWER : '';
      const cycleDemandPower_kW = row.CYCLE_DEMAND_POWER !== -1 ? Math.floor(row.CYCLE_DEMAND_POWER * latest_data.COEFFICIENT * latest_data.COEFFICIENT_MAGNIFICATION * parseFloat(latest_data.UNIT_OF_DEMAND_POWER.match(/[\d.]+/))) : '';
      const monthlyMaximumDemandPower = row.MONTHLY_MAXIMUM_DEMAND_POWER !== -1 ? row.MONTHLY_MAXIMUM_DEMAND_POWER : '';
      const monthlyMaximumDemandPower_kW = row.MONTHLY_MAXIMUM_DEMAND_POWER !== undefined && row.MONTHLY_MAXIMUM_DEMAND_POWER !== -1 ? Math.floor(row.MONTHLY_MAXIMUM_DEMAND_POWER * latest_data.COEFFICIENT * latest_data.COEFFICIENT_MAGNIFICATION * parseFloat(latest_data.UNIT_OF_DEMAND_POWER.match(/[\d.]+/))) : '';
    
      csvData += `${row.METER_ID},${row.GET_DATE},${cycleActiveEnergy},${cycleDemandPower},${cycleDemandPower_kW},${monthlyMaximumDemandPower},${monthlyMaximumDemandPower_kW}\n`;
    });

    // 現在の日付と時刻を取得
    const currentDateTime = getCurrentDateTime();

    // UTF-8文字コードでBlobを生成
    const blob = new Blob([String.fromCharCode(0xfeff), csvData], { type: 'text/csv; charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `[${serial_no}]詳細データ_${currentDateTime}.csv`;
    link.click();
  };

  return (
      <React.Fragment>
        {isLoading ? ( // 読み込み中の場合、ローディング画面を表示
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
          <CircularProgress /> Loading...
        </div>
        ) : (
          <div>
            <Title>検針値履歴</Title>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', marginTop: '16px' }}>
              <Button
                id="prevButton" 
                variant="contained" 
                color="primary" 
                onClick={handlePrevClick} 
                style={{ marginRight: '8px' }}
                disabled={meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId) === 0}
              >
                <SkipPreviousIcon />
                前へ
              </Button>
              <Button
                id="nextButton"
                variant="contained"
                color="primary"
                onClick={handleNextClick}
                disabled={meter_list.findIndex((meter) => meter.METER_ID === selectedMeterId) === meter_list.length - 1}
              >
                <SkipNextIcon />
                次へ
              </Button>
            </div>
            <div>
              <Typography component="p" variant="h6">
                計器ID:
                <select style={inputStyle} id='METER_LIST' onChange={handleChangeMeterId}>
                    {meter_list.map((meter) => {
                    return <option key={meter.METER_ID} selected={meter.METER_ID === selectedMeterId}>{meter.SERIAL_NO}</option>;
                    })}
                </select>
              </Typography>
              <Typography component="p" variant="h6" sx={{ marginTop:'20px'}}>
                30分値指定
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RadioGroup
                  value={selectedPeriod}
                  onChange={togglePeriod}
                  row
                >
                  <FormControlLabel
                    value='0'
                    control={<Radio color="primary" />}
                    label="全期間"
                  />
                  <FormControlLabel
                    value='1'
                    control={<Radio color="primary" />}
                    label="期間指定"
                  />
                </RadioGroup>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  <InputLabel shrink htmlFor="start-date">
                    開始日
                  </InputLabel>
                  <TextField
                    id="start-date"
                    type="date"
                    value={startDate}
                    onChange={(e) => handleChangeStartDate(e)}
                    style={{ marginTop: 0, marginBottom: 0 }} // heightプロパティを設定
                    disabled={edit_flg}
                  />
                  <InputLabel shrink htmlFor="end-date">
                    終了日
                  </InputLabel>
                  <TextField
                    id="end-date"
                    type="date"
                    value={endDate}
                    onChange={(e) => handleChangeEndDate(e)}
                    style={{ marginTop: 0, marginBottom: 0 }} // heightプロパティを設定
                    disabled={edit_flg}
                  />
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <Button variant="contained" color="primary" onClick={() => handleSearchClick(null)}>
                    <SearchIcon />検索
                  </Button>
                </div>
                {showTable && (
                  <div style={{ marginLeft: '20px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCSVExport}
                    >
                      <DownloadIcon/>CSV出力
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {showTable && (
              <div>
                <Typography component="p" variant="h6" sx={{ marginTop:'30px'}}>
                  詳細情報
                </Typography>
                <Paper sx={{ width: '100%' ,overflow:'hidden'}}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {latest_data_header.map((header,index) => (
                            <TableCell key={index}>
                              {header.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {latest_data_header.map((header, columnIndex) => (
                            <TableCell key={columnIndex} style={{minWidth:header.minWidth,overflow:'hidden'}}>
                              {getCellContent(header.label, latest_data)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>  
                </Paper>
              </div>
            )}
            {showTable && (
              <div>
                <Typography component="p" variant="h6" sx={{ marginTop:'40px'}}>
                  30分値 ※電力量(kWh) or 電力(kW) ＝ 指針値 ×係数×係数の倍率×単位
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width:'10%'}}>取得日時</TableCell>
                      <TableCell style={{width:'20%'}}>定時積算有効電力量計測値</TableCell>
                      <TableCell style={{width:'20%'}}>定時需要電力(指針値)</TableCell>
                      <TableCell style={{width:'15%'}}>定時需要電力(kW)</TableCell>
                      <TableCell style={{width:'20%'}}>月間最大需要電力(指針値)</TableCell>
                      <TableCell style={{width:'25%'}}>月間最大需要電力(kW)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history_list
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <TableRow key={row.METER_ID}>
                          <TableCell>{row.GET_DATE}</TableCell>
                          <TableCell>{row.CYCLE_ACTIVE_ENERGY !== -1 ? row.CYCLE_ACTIVE_ENERGY : ''}</TableCell>
                          <TableCell>{row.CYCLE_DEMAND_POWER !== -1 ? row.CYCLE_DEMAND_POWER : ''}</TableCell>
                          <TableCell>{row.CYCLE_DEMAND_POWER !== -1 ? Math.floor(row.CYCLE_DEMAND_POWER * latest_data.COEFFICIENT * latest_data.COEFFICIENT_MAGNIFICATION * parseFloat(latest_data.UNIT_OF_DEMAND_POWER.match(/[\d.]+/))) : ''}</TableCell>
                          <TableCell>{row.MONTHLY_MAXIMUM_DEMAND_POWER !== undefined && row.MONTHLY_MAXIMUM_DEMAND_POWER !== -1 ? row.MONTHLY_MAXIMUM_DEMAND_POWER : ''}</TableCell>
                          <TableCell>{row.MONTHLY_MAXIMUM_DEMAND_POWER !== undefined && row.MONTHLY_MAXIMUM_DEMAND_POWER !== -1 ? Math.floor(row.MONTHLY_MAXIMUM_DEMAND_POWER * latest_data.COEFFICIENT * latest_data.COEFFICIENT_MAGNIFICATION * parseFloat(latest_data.UNIT_OF_DEMAND_POWER.match(/[\d.]+/))) : ''}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
            {showTable && (
              <TablePagination
                component="div"
                count={history_list.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                labelRowsPerPage="表示数"
              />
            )}

          </div>
        )}
      </React.Fragment>
  );
}

History.propTypes = {
  meter_id: PropTypes.string.isRequired, 
  company_id: PropTypes.string.isRequired,
  privilegeFlg: PropTypes.string.isRequired,
  onMeterSelect:PropTypes.func,
  selectedPlan:PropTypes.string.isRequired,

  // 他のプロパティのバリデーション
};
