import * as React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; // react-router-domからuseNavigateをインポート
import axios from "axios";

const App = () => {
  const navigate = useNavigate(); // useNavigateを使用してページ遷移を設定
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const apiVer = process.env.REACT_APP_APP_VER;

  // データをローカルストレージに保存する関数
  const saveDataToLocalStorage = (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
  };

  // Unicode エスケープをデコードするカスタム関数
  function unicodeDecode(str) {
    return str.replace(/\\u([\d\w]{4})/gi, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  }

  const isEmailValid = (email) => {
    // メールアドレスの正規表現を使用してフォーマットを検証
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    try {

      // 入力チェック
      if (!email || !password) {
        setErrorMessage("メールアドレス、パスワードどちらかが未入力です");
        return; // メールアドレスまたはパスワードが未入力の場合、処理を終了
      }

      if (!isEmailValid(email)) {
        setErrorMessage("正しいメールアドレスの形式ではありません");
        return;
      }

      const requestData = {
        path: "/login",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      };

      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // レスポンスのステータスコードに応じて処理
      if (response.data.statusCode === 200) {
        // バックスラッシュを取り除く
        const cleanedBody = response.data.body.replace(/\\/g, '');
        const decodedBody = unicodeDecode(cleanedBody);

        // パースする
        const parsedBody = JSON.parse(decodedBody);
        
        // 配列の最初の要素を取得
        const firstElement = parsedBody[0];

        // firstElement から USER_NAME を取得
        const userName = firstElement.USER_NAME;
        const companyId = firstElement.COMPANY_ID;
        const userId = firstElement.USER_ID;
        const privilegeFlg = firstElement.PRIVILEGE_FLG;

        // データをローカルストレージに保存
        saveDataToLocalStorage({
          userName: userName,
          companyId: companyId,
          userId: userId,
          privilegeFlg: privilegeFlg
        });
        navigate("/home");
        
      } else {
        // ログイン失敗時の処理
        const body = JSON.parse(response.data.body);
        setErrorMessage(body.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("ログインエラーが発生しました" + error.message);
    }
  };

  return (
    <>
      <Container maxWidth="md">
        <Typography component="h1" variant="h4" sx={{ textAlign: 'center',marginTop: '70px'}}>
            クラウド型<br />
            高圧Bルート検針システム（dWatch） Ver{apiVer}<br />
            by <img src="/images/OSAKI_LOGO.bmp" style={{ width: '100px', height: 'auto', marginRight: '10px' }}/>
            <img src="/images/OSAKI_WIDTH_REGULAR.BMP" style={{ width: '150px', height: 'auto' }}/>
          </Typography>
      </Container>

      <Container maxWidth="xs">    
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          

          <Box
            component="form"
            noValidate
            sx={{ mt:1 }}
            onSubmit={(e) => {
              e.preventDefault(); // デフォルトのサブミットを防止
              handleLogin(); // ログイン処理を実行
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />

            {errorMessage && (
              <Typography variant="body2" style={{ color: 'red' }}>
                {errorMessage}
              </Typography>
            )}

            <Button
              type="submit" // typeを"button"に設定
              fullWidth
              variant="contained"
              sx={{ mt:3, mb:2 }}
              onClick={handleLogin} // ログインボタンが押下された際にhandleLogin関数を呼び出す
            >
              ログイン
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default App;