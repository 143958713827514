import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types'; // prop-typesパッケージからPropTypesをインポート

export default function Title(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired, // 'children'プロパティのバリデーション
  // 他のプロパティのバリデーション
};