// ModalContent.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from "axios";

const meter_list_header = [
  '計器ID',
  '設置場所',
  '目標電力(kW)',
  '契約電力(kW)',
];

const meter_list_header_kenshin = [
  '計器ID',
  '設置場所',
];

const meter_list_header_admin = [
  '管理ID',
  '計器ID',
  'MACアドレス',
  '設置場所',
  '目標電力(kW)',
  '契約電力(kW)',
  'ゲートウェイ名',
];

const gateway_list_header = [
  'ゲートウェイID',
  'ゲートウェイ名',
  'MACアドレス',
  '会社名',
];

const user_list_header = [
  'ユーザーID',
  'ユーザー名',
  'メールアドレス',
  'パスワード',
  '権限',
];

const meter_list_header_new = [
  '計器ID',
  'MACアドレス',
  '設置場所',
  '目標電力(kW)',
  '契約電力(kW)',
  'ゲートウェイ名',
];

const gateway_list_header_new = [
  'ゲートウェイ名',
  'MACアドレス',
  '会社名',
];

const user_list_header_new = [
  'ユーザー名',
  'メールアドレス',
  'パスワード',
  '権限',
  '会社名',
];

const getCellContent = (header, row, handleChange,info_flg,company_list,privilegeFlg,gateway_list) => {
  
  // スタイルを追加する
  const selectStyle = {
    fontSize: '16px', // フォントサイズを変更
    padding: '10px',   // パディングを変更
    width:'120px',
    // 他にも必要なスタイルを追加
  };

  const validateInput = (value) => {
    // 半角英数字以外の文字を除去
    return value.replace(/[^0-9a-zA-Z]+/g, '');
  };

  const validateInputNum = (value) => {
    // 半角数字以外の文字を除去
    return value.replace(/^0+|[^0-9]+/g, '');
  };
  
  const privilege_list =  ["デマンドプラン", "管理者", "検針プラン"];
  
  switch (header) {
    case '管理ID':
      if(row){
        return row.METER_ID;
      }
      break;
    case '計器ID':
      if(row){
        return (
          <TextField
            id='SERIAL_NO'
            defaultValue={row.SERIAL_NO}
            onChange={(e) => {
              const validatedValue = validateInput(e.target.value);
              e.target.value = validatedValue;
              handleChange('SERIAL_NO', validatedValue)
            }}
            inputProps={{
              maxLength: 7, // 最大桁数を半角7桁に設定
              pattern: '^[0-9a-zA-Z]*$', // 半角英数字のみ許可
            }}
          />
        );
      }else{
        return (
          <TextField
            id='SERIAL_NO'
            defaultValue=""
            onChange={(e) => {
              const validatedValue = validateInput(e.target.value);
              e.target.value = validatedValue;
              handleChange('SERIAL_NO', validatedValue)
            }}
            inputProps={{
              maxLength: 7, // 最大桁数を半角7桁に設定
              pattern: '^[0-9a-zA-Z]*$', // 半角英数字のみ許可
            }}
          />
        );
      }
      
    case '設置場所':
      if(row){  
        return (
          <TextField
            id='PLACE'
            defaultValue={row.PLACE}
            onChange={(e) => handleChange('PLACE', e.target.value)}
          />
        );
      }else{
        return (
          <TextField
            id='PLACE'
            defaultValue=""
            onChange={(e) => handleChange('PLACE', e.target.value)}
          />
        );
      }
    case '契約電力(kW)':
      if(row){
        return (
          <TextField
            id='CONTRACT_POWER'
            defaultValue={row.CONTRACT_POWER}
            onChange={(e) => {
              const validatedValue = validateInputNum(e.target.value);
              e.target.value = validatedValue;
              handleChange('CONTRACT_POWER', validatedValue)
            }}
            inputProps={{
              pattern: '^[0-9]*$',
            }}
          />
        );
      }else{
        return (
          <TextField
            id='CONTRACT_POWER'
            defaultValue=""
            onChange={(e) => {
              const validatedValue = validateInputNum(e.target.value);
              e.target.value = validatedValue;
              handleChange('CONTRACT_POWER', validatedValue)
            }}
          />
        );
      }
    case '目標電力(kW)':
      if(row){
        return (
          <TextField
            id='GOAL_POWER'
            defaultValue={row.GOAL_POWER}
            onChange={(e) => {
              const validatedValue = validateInputNum(e.target.value);
              e.target.value = validatedValue;
              handleChange('GOAL_POWER', validatedValue)
            }}
            
          />
        );
      }else{
        return (
          <TextField
            id='GOAL_POWER'
            defaultValue=""
            onChange={(e) => {
              const validatedValue = validateInputNum(e.target.value);
              e.target.value = validatedValue;
              handleChange('GOAL_POWER', validatedValue)
            }}
            
          />
        );
      }
    case 'ゲートウェイID':
      if(row){
        return row.GATEWAY_ID;
      }
      break;
    case 'ゲートウェイ名':
      if(privilegeFlg === 1 && info_flg === '0'){
        if(row){
          return (
            <div>
              <select style={selectStyle} id='GATEWAY_LIST' onChange={(e) => handleChange('GATEWAY_ID', gateway_list[e.target.selectedIndex].GATEWAY_ID)}>
                {gateway_list.map((gateway) => {
                return <option key={gateway.GATEWAY_ID} selected={gateway.GATEWAY_ID === row.GATEWAY_ID}>{gateway.GATEWAY_NAME}</option>;
                })}
              </select>
            </div>
          );
        }else{
          return (
            <div>
              <select style={selectStyle} id='GATEWAY_LIST' onChange={(e) => handleChange('GATEWAY_ID', gateway_list[e.target.selectedIndex].GATEWAY_ID)}>
                {gateway_list.map((gateway) => {
                return <option key={gateway.GATEWAY_ID}>{gateway.GATEWAY_NAME}</option>;
                })}
              </select>
            </div>
          );
        }
      }else{
        if(row){
          return (
            <TextField 
              id='GATEWAY_NAME'
              defaultValue={row.GATEWAY_NAME}
              onChange={(e) => handleChange('GATEWAY_NAME', e.target.value)}
            />
          );
        }else{
          return (
            <TextField 
              id='GATEWAY_NAME'
              defaultValue=""
              onChange={(e) => handleChange('GATEWAY_NAME', e.target.value)}
            />
          );  
        }
      }
      
    case 'MACアドレス':
      if(row){
        return (
          <TextField
            id='MAC_ADDRESS'
            defaultValue={row.MAC_ADDRESS}
            onChange={(e) => {
              // 半角英数字とコロン（:）のみを許容し、小文字を大文字に変換
              const validatedValue = e.target.value.replace(/[^0-9a-fA-F:]+/g, '').toUpperCase();
              e.target.value = validatedValue;
              handleChange('MAC_ADDRESS', validatedValue);
            }}
            inputProps={{
              maxLength: 17, // 最大桁数を半角7桁に設定
              pattern: '^[0-9a-zA-Z]*$', // 半角英数字のみ許可
            }}
          />
        );
      }else{
        return (
          <TextField
            id='MAC_ADDRESS'
            defaultValue=""
            onChange={(e) => {
              // 半角英数字とコロン（:）のみを許容し、小文字を大文字に変換
              const validatedValue = e.target.value.replace(/[^0-9a-fA-F:]+/g, '').toUpperCase();
              e.target.value = validatedValue;
              handleChange('MAC_ADDRESS', validatedValue);
            }}
            inputProps={{
              maxLength: 17, // 最大桁数を半角7桁に設定
              pattern: '^[0-9a-zA-Z]*$', // 半角英数字のみ許可
            }}
          />
        );
      }
      
    case 'ユーザーID':
      if(row){
        return row.USER_ID;
      }
      break;
    case 'ユーザー名':
      if(row){
        return (
          <TextField
            id='USER_NAME'
            defaultValue={row.USER_NAME}
            onChange={(e) => handleChange('USER_NAME', e.target.value)}
          />
        );
      }else{
        return (
          <TextField
            id='USER_NAME'
            defaultValue=""
            onChange={(e) => handleChange('USER_NAME', e.target.value)}
          />
        );
      }
    case 'メールアドレス':
      if(row){
        return (
          <TextField
            id='MAIL_ADDRESS'
            defaultValue={row.MAIL_ADDRESS}
            onChange={(e) => handleChange('MAIL_ADDRESS', e.target.value)}
          />
        );
      }else{
        return (
          <TextField
            id='MAIL_ADDRESS'
            defaultValue=""
            onChange={(e) => handleChange('MAIL_ADDRESS', e.target.value)}
          />
        );
      }
    case 'パスワード':
      if(row){
        return (
          <TextField
          id='PASSWORD'
            defaultValue={row.PASSWORD}
            onChange={(e) => handleChange('PASSWORD', e.target.value)}
          />
        );
      }else{
        return (
          <TextField
          id='PASSWORD'
            defaultValue=""
            onChange={(e) => handleChange('PASSWORD', e.target.value)}
          />
        );
      }
      
    case '権限':
      if(row){
        return (
          <div>
            <select style={selectStyle} id='PRIVILEGE_LIST' onChange={(e) => handleChange('PRIVILEGE_FLG', e.target.selectedIndex)}>
              {privilege_list.map((privilege,index) => {
              return <option key={index} selected={index === row.PRIVILEGE_FLG}>{privilege}</option>;
              })}
            </select>
          </div>
        );
      }else{
        return (
          <div>
            <select style={selectStyle} id='PRIVILEGE_LIST' onChange={(e) => handleChange('PRIVILEGE_FLG', e.target.selectedIndex)}>
              {privilege_list.map((privilege,index) => {
              return <option key={index}>{privilege}</option>;
              })}
            </select>
          </div>
        );
      }
      
    case '会社名':
      if(info_flg === '1' || (info_flg === '0' && privilegeFlg === 1)){
        if(row){
          return (
            <div>
              <select style={selectStyle} id='COMPANY_LIST' onChange={(e) => handleChange('COMPANY_ID', company_list[e.target.selectedIndex].COMPANY_ID)}>
                {company_list.map((company) => {
                return <option key={company.COMPANY_ID} selected={company.COMPANY_ID === row.COMPANY_ID}>{company.COMPANY_NAME}</option>;
                })}
              </select>
            </div>
          );
        }else{
          return (
            <div>
              <select style={selectStyle} id='COMPANY_LIST' onChange={(e) => handleChange('COMPANY_ID', company_list[e.target.selectedIndex].COMPANY_ID)}>
                {company_list.map((company) => {
                return <option key={company.COMPANY_ID}>{company.COMPANY_NAME}</option>;
                })}
              </select>
            </div>
          );
        }
        
      }else if(info_flg === '2'){
        return (
          <TextField
          id='COMPANY_NAME'
            defaultValue=""
            onChange={(e) => handleChange('COMPANY_NAME', e.target.value)}
          />
        );
      }
      break; // ここで break を追加
    default:
      return '';
  }
};

const getId = (info_flg,row) => {
  switch(info_flg){
    case '0':
      return row.METER_ID;
    case '1':
      return row.GATEWAY_ID;
    case '2':
      return row.USER_ID;
    default:
      return '';
  }
};

const getParams = (info_flg,row,update_user_id,company_list,privilegeFlg,gateway_list,selectedPlan) => {
  
  if (!row) {
    return null; // 空のオブジェクトを返して処理を中断
  }
  
  // チェック用のフラグ
  let hasEmptyField = false;

  // 空の項目があるかどうかをチェック
  const checkEmptyField = (fieldName) => {
    if (!row[fieldName]) {
      hasEmptyField = true;
    }
  };

  switch (info_flg) {
    case '0':
      // ... その他の処理
      checkEmptyField('SERIAL_NO');
      checkEmptyField('PLACE');
      if((privilegeFlg === 0 || privilegeFlg === 1) && selectedPlan == 'demand'){
        checkEmptyField('CONTRACT_POWER');
        checkEmptyField('GOAL_POWER');
      }
      if (privilegeFlg === 1 && selectedPlan == 'demand'){
        checkEmptyField('MAC_ADDRESS');
      }
      break;
    case '1':
      // ... その他の処理
      checkEmptyField('GATEWAY_NAME');
      checkEmptyField('MAC_ADDRESS');
      break;
    case '2':
      // ... その他の処理
      checkEmptyField('USER_NAME');
      checkEmptyField('MAIL_ADDRESS');
      checkEmptyField('PASSWORD');
      break;
    default:
      break;
  }

  // 空の項目がある場合はアラートを表示
  if (hasEmptyField) {
    return null; // 空のオブジェクトを返して処理を中断
  }

  let params = {};
  switch(info_flg){
    case '0':
      if(privilegeFlg === 1 && selectedPlan == 'demand'){
        if(!row.GATEWAY_ID){
          row.GATEWAY_ID = gateway_list[0].GATEWAY_ID
          row.COMPANY_ID = gateway_list[0].COMPANY_ID
        }else{
          const selectedGw = gateway_list.find(gw => gw.GATEWAY_ID === row.GATEWAY_ID);
          row.COMPANY_ID = selectedGw.COMPANY_ID;
        }
        
        params = 
        {
          SERIAL_NO: row.SERIAL_NO,
          PLACE: row.PLACE,
          CONTRACT_POWER: parseInt(row.CONTRACT_POWER),
          MAC_ADDRESS: row.MAC_ADDRESS,
          GOAL_POWER: parseInt(row.GOAL_POWER),
          COMPANY_ID: row.COMPANY_ID,
          GATEWAY_ID: row.GATEWAY_ID,
          UPDATE_USER_ID:update_user_id,
        }
      }else if(privilegeFlg === 2 || selectedPlan == 'kensin'){
        params = 
        {
          SERIAL_NO: row.SERIAL_NO,
          PLACE: row.PLACE,
          UPDATE_USER_ID:update_user_id,
        }
      }
      else{
        params = 
        {
          SERIAL_NO: row.SERIAL_NO,
          PLACE: row.PLACE,
          CONTRACT_POWER: parseInt(row.CONTRACT_POWER),
          GOAL_POWER: parseInt(row.GOAL_POWER),
          UPDATE_USER_ID:update_user_id,
        }
      }
      
      return params;
    case '1':
      if(!row.COMPANY_ID){
        row.COMPANY_ID = company_list[0].COMPANY_ID
      }
      params = 
      {
        GATEWAY_NAME: row.GATEWAY_NAME,
        MAC_ADDRESS: row.MAC_ADDRESS,
        COMPANY_ID: row.COMPANY_ID,
        UPDATE_USER_ID:update_user_id,
      }
      return params;
    case '2':
      if (!row.PRIVILEGE_FLG){
        row.PRIVILEGE_FLG = '0'
      }
      params = 
      {
        USER_NAME: row.USER_NAME,
        MAIL_ADDRESS: row.MAIL_ADDRESS,
        PASSWORD: row.PASSWORD,
        PRIVILEGE_FLG: parseInt(row.PRIVILEGE_FLG),
        UPDATE_USER_ID:update_user_id,
      }
      return params;
    default:
      return params;
  }
};

const fetchDataFromServer = async () => {
  try {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

    const requestData = {
      path: '/get_gw_company',
    };

    const response = await axios.post(apiEndpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.data && response.data.statusCode === 200) {
      return response.data.body; // レスポンスのデータを返す
    } else {
      console.error('Error in API response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return null;
  }
};

const RegisterModal = ({ closeModal,rowData,title,login_user_id,privilegeFlg,selectedPlan }) => {
  const [header_rows, setHeaderRows] = useState([]);
  const [info_flg,setInfoFlg] = useState('0');
  const [currentRowData, setCurrentRowData] = useState(null);
  const [bebore_goal_power,setBeforeGoalPower] = useState(null);
  let [title_text,setTitleText] = useState('新規');
  const [company_list,setCompanyList] = useState([]);
  const [gateway_list,setGatewayList] = useState([]);

  useEffect(() => {
    setCurrentRowData(rowData);
    if(rowData){
      setTitleText('編集')
      if (title === "計器一覧") {
        if(privilegeFlg === 1 && selectedPlan === 'demand'){
          setHeaderRows(meter_list_header_admin);
          fetchData();
        }else if(privilegeFlg === 2 || selectedPlan === 'kensin'){
          setHeaderRows(meter_list_header_kenshin);
        }
        else{
          setHeaderRows(meter_list_header);
        }
        setBeforeGoalPower(rowData.GOAL_POWER);
        setInfoFlg('0');
      } else if (title === "GW一覧") {
        setHeaderRows(gateway_list_header);
        setInfoFlg('1');
        fetchData();
      } else if (title === "ユーザー一覧") {
        setHeaderRows(user_list_header);
        setInfoFlg('2')
      }
    }else{
      setTitleText('新規')
      if (title === "計器一覧") {
        setHeaderRows(meter_list_header_new);
        fetchData();
        setInfoFlg('0');
      } else if (title === "GW一覧") {
        setHeaderRows(gateway_list_header_new);
        setInfoFlg('1');
        fetchData('1');
      } else if (title === "ユーザー一覧") {
        setHeaderRows(user_list_header_new);
        setInfoFlg('2')
      }
    }
    
    
  }, [rowData,title]);

  const fetchData = async (info_flg) => {
    try {
      const data = await fetchDataFromServer(info_flg);
      if (data) {
        const dataArray = JSON.parse(data);
        setCompanyList(dataArray['COMPANY_LIST']);
        setGatewayList(dataArray['GATEWAY_LIST']);
      }
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };

  const handleChange = (field, value) => {
    // テーブルの行データを更新
    setCurrentRowData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // スタイルを追加する
  const closeIconStyle = {
    position: 'absolute',
    top: '10px',
    right: '50px',
    cursor: 'pointer',
    zIndex: 1, // ボタンが被っている可能性があるため zIndex を設定
    
  };

  const updateButtonStyle = {
    position: 'absolute',
    bottom: '50px', // 下端からの位置を指定
    right: '50px', // 右端からの位置を指定
  };

  // 更新ボタンがクリックされたときの処理
  const handleUpdate = async () => {
    try {

      const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

      if(!privilegeFlg){
        privilegeFlg = '0';
      }
      
      const params = getParams(info_flg,currentRowData,login_user_id,company_list,privilegeFlg,gateway_list,selectedPlan);
      if(params === null){
        alert('未入力の項目があります');
        return;
      }

      let reg_flg = '0'
      if (title_text === '新規' ){
        reg_flg = '0'
      }else if(title_text === '編集'){
        reg_flg = '1'
      }
      const id = getId(info_flg,currentRowData);
      let company_name = '';
      if(info_flg === '2'){
        company_name = currentRowData.COMPANY_NAME
        if(company_name === ''){
          alert('未入力の項目があります');
          return;
        }
      }

      const requestData = {
        path: '/update_info',
        body: JSON.stringify({
          reg_flg: reg_flg,
          id: id,
          info_flg: info_flg,
          params: params,
          company_name : company_name,
          privilege_flg: privilegeFlg,
          bebore_goal_power:bebore_goal_power,
          selected_plan:selectedPlan
        }),
      };

      // Lambda 関数を呼び出す
      const response = await axios.post(apiEndpoint, requestData);

      if (response && response.data && response.data.statusCode === 200) {
        // レスポンスをログに出力
        console.log('Lambda 関数のレスポンス:', response.data);
        alert("更新完了しました");
        closeModal();


        // データの再読み込みなど、更新後の処理を追加することができます
      } else {
        const body = response.data.body
        const parsedBody = JSON.parse(body);
        const message = parsedBody.message;
        alert(message);
      }
    } catch (error) {
      alert('Error calling Lambda function:', error);
    }
  };

  return (
    <div>
      <Title>{title_text}</Title>
      {/* モーダルのコンテンツ */}
      <button style={closeIconStyle} onClick={closeModal}><CloseIcon/></button>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {header_rows.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {header_rows.map((header, columnIndex) => (
              <TableCell key={columnIndex}>{getCellContent(header, rowData, handleChange,info_flg,company_list,privilegeFlg,gateway_list)}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      
      {/* スペースを追加 */}
      <div style={{ margin: '20px 0' }}></div>
        <div style={updateButtonStyle}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#ccc', color: 'black' }}
            onClick={() => {
              handleUpdate();
            }}
          >
          更新
          </Button>
        </div>
      </div>
  );
};

RegisterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  rowData: PropTypes.object,  // 編集中の行のデータ
  title: PropTypes.string,
  login_user_id:PropTypes.string,
  privilegeFlg:PropTypes.int,
  selectedPlan:PropTypes.string,
};

export default RegisterModal;