import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MonitorIcon from '@mui/icons-material/Monitor';
import HistoryIcon from '@mui/icons-material/History';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PropTypes from 'prop-types'; // prop-typesをインポート

export default function MainListItems({ setButtonText,setActiveTab2,onMeterSelect,privilegeFlg,selectedPlan}) {
  const handleHome = () => {
    
    toggleSetting("ホーム");
  };

  const handleSetting = () => {
    toggleSetting("設定");
  };

  const handleMasterSetting = () => {
    toggleSetting("管理者メニュー");
  };

  const handleDemand = () => {
    onMeterSelect("");
    toggleSetting("リアルタイム監視");
    setActiveTab2(0);
  };

  const handleDemandHistory = () => {
    onMeterSelect("");
    toggleSetting("過去デマンド");
    setActiveTab2(1);
  };

  const handleHistory = () => {
    onMeterSelect("");
    toggleSetting("検針値履歴");
    setActiveTab2(2);
  };
  
  // mainListItems内で設定ボタンがクリックされたときに呼ばれる関数
  const toggleSetting = (text) => {
    setButtonText(text); // buttonTextを「設定」に変更
  };
  
  return (
    <React.Fragment>
      <ListItemButton onClick={handleHome}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="ホーム" />
      </ListItemButton>
      {(privilegeFlg === 0 || privilegeFlg === 1) && selectedPlan === 'demand' && (
        <>
          <ListItemButton onClick={handleDemand}>
            <ListItemIcon>
              <MonitorIcon />
            </ListItemIcon>
            <ListItemText primary="リアルタイム監視" />
          </ListItemButton>
        </>
      )}
      {(privilegeFlg === 0 || privilegeFlg === 1) && selectedPlan === 'demand' && (
        <>
          <ListItemButton onClick={handleDemandHistory}>
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary="過去デマンド" />
          </ListItemButton>
        </>
      )}  
      <ListItemButton onClick={handleHistory}>
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText primary="検針値履歴" />
      </ListItemButton>
      {(privilegeFlg === 0 || privilegeFlg === 1) && selectedPlan === 'demand' && (
        <>
          <ListItemButton onClick={handleSetting}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="設定" />
          </ListItemButton>
        </>
      )}
      {privilegeFlg === 1 && selectedPlan === 'demand' &&(
        <>
          <ListItemButton onClick={handleMasterSetting}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="管理者メニュー" />
          </ListItemButton>
        </>
      )}
      
    </React.Fragment>
  );

}

MainListItems.propTypes = {
  setButtonText: PropTypes.func.isRequired,
  setActiveTab2:PropTypes.func.isRequired,
  onMeterSelect:PropTypes.func.isRequired,
  privilegeFlg: PropTypes.string.isRequired,
  selectedPlan:PropTypes.string.isRequired,
};

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );