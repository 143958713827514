import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../home/Title';
import axios from 'axios';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';

const fetchDataFromServer = async (company_id) => {
  try {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

    const requestData = {
      path: '/get_notice',
      body: JSON.stringify({
        company_id: company_id,
      }),
    };

    const response = await axios.post(apiEndpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.data && response.data.statusCode === 200) {
      return response.data.body; // レスポンスのデータを返す
    } else {
      console.error('Error in API response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return null;
  }
};

export default function Notice({companyId,login_user_id}) {

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const [phoneNumber, setPhoneNumber] = useState(''); // 電話番号を格納
  const [authCode, setAuthCode] = useState(''); // 電話番号を格納
  const [email, setEmail] = useState(''); // メールアドレスを格納
  const [email2, setEmail2] = useState(''); // メールアドレスを格納
  const [time_from, setTimeFrom] = useState(''); 
  const [time_to, setTimeTo] = useState('');
  const [sms_notice, setSmsNotice] = useState(false);
  const [mail_notice, setMailNotice] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('0'); // 初期選択は期間指定
  const [sms_auth_status_text,setSmsAuthStatusText] = useState('未認証');

  useEffect(() => {
    setIsEditing(false);
    fetchData(); // 初回描画時にデータを取得する
  }, [companyId]);

  const togglePeriod = (event) => {
    setSelectedPeriod(event.target.value === '0' ? '0' : '1');
  };

  const handleChangeStartHour = (event) => {
    setTimeFrom(event.target.value);
  };

  const handleChangeEndHour = (event) => {
    setTimeTo(event.target.value);
  };

  const handleEmailChange = (e) => {
    const input = e.target.value;
  
    // 正規表現で半角英数記号以外を削除
    const cleanedInput = input.replace(/[^\x21-\x7E]/g, '');
  
    return cleanedInput;
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
  
    // 正規表現で半角数字以外を削除
    const cleanedInput = input.replace(/[^0-9]/g, '').slice(0, 11);
    
    return cleanedInput;
  };
  

  // 編集ボタンがクリックされたときの処理
  const handleEditClick = (prevIsEditing) => {
    if (!prevIsEditing) {
      const userConfirmed = window.confirm('修正内容が消去されます。よろしいですか？');
      if (!userConfirmed) {
        return;
      }
      fetchData();
    }
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const fetchData = async () => {
    try {
      const data = await fetchDataFromServer(companyId);
      if (data) {
        const dataArray = JSON.parse(data);
        setDefaultData(dataArray);
      }
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };

  const setDefaultData = async (dataArray) => {
    if (dataArray) {
      // dataArray からデータを使って state 変数を更新
      setPhoneNumber(dataArray.NOTICE_PHONE_NUMBER1 || '');
      setEmail(dataArray.NOTICE_MAIL_ADDRESS1 || '');
      setEmail2(dataArray.NOTICE_MAIL_ADDRESS2 || '');
      const timeFromHours = dataArray.NOTICE_TIME_FROM
      ? dataArray.NOTICE_TIME_FROM.split(':')[0]
      : '';
      setTimeFrom(timeFromHours);
      const timeToHours = dataArray.NOTICE_TIME_TO
      ? dataArray.NOTICE_TIME_TO.split(':')[0]
      : '';
      setTimeTo(timeToHours);
      setSmsNotice(dataArray.NOTICE_SMS_FLG === 1);
      setMailNotice(dataArray.NOTICE_MAIL_FLG === 1);
      setSelectedPeriod(dataArray.NOTICE_PERIOD_FLG || '0');
      setAuthCode('');
      setSmsAuthStatusText(dataArray.SMS_AUTH_STATUS === 1 ? '認証済':'未認証');

    }
  };

  // 電話番号が空または11桁未満の場合はエラーを表示する関数
  const validatePhoneNumber = (number) => {
    if (!number || number.length < 10) {
      alert("電話番号を正しく入力してください");
      return false;
    }
    return true;
  };

  // メールアドレスが空の場合はエラーを表示する関数
  const validateMailAddress = (address) => {
    if (!address) {
      alert("メールアドレスを正しく入力してください");
      return false;
    }
    return true;
  };

  const handleAuthCodeSend = () => {
    // 電話番号のバリデーションチェック
    if (!validatePhoneNumber(phoneNumber)) {
      return;
    }
    const userConfirmed = window.confirm('認証コードを送信しますか？');
    if (!userConfirmed) {
      return; // ユーザーが「いいえ」を選択した場合は何もしない
    }
    const updatedPhoneNumber = phoneNumber;
    const  requestData = {
      path: "/generate_auth_code",
      body: JSON.stringify({
        phoneNumber: updatedPhoneNumber,
        company_id: companyId,
        update_user_id:login_user_id,
      }),
    };

    axios.post(apiEndpoint, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      // レスポンスのステータスコードに応じて処理
      if (response.data.statusCode === 200) {
        alert("入力した電話番号に認証コードを発行しました。\n※15分以上待っても届かない場合は\n再度「認証コード発行」を押してください。");
      } else {
        // ログイン失敗時の処理
        const body = JSON.parse(response.data.body);
        alert("認証コード送信失敗しました エラー:" + body.message);
      }
    }).catch((error) => {
      console.error("Error during login:", error);
      alert("認証コード送信失敗しました エラー:" + error.message);
    });
  };
  
  const handleEmailTestSend = () => {
    if(!validateMailAddress(email)){
      return;
    }
    const userConfirmed = window.confirm('テスト送信しますか？');
    if (!userConfirmed) {
      return; // ユーザーが「いいえ」を選択した場合は何もしない
    }
    const updatedEmail = email;
    handleTestSend("email",updatedEmail);
  };
  
  const handleEmailTestSend2 = () => {
    if(!validateMailAddress(email2)){
      return;
    }
    const userConfirmed = window.confirm('テスト送信しますか？');
    if (!userConfirmed) {
      return; // ユーザーが「いいえ」を選択した場合は何もしない
    }
    const updatedEmail = email2;
    handleTestSend("email",updatedEmail);
  };

  // メールを送信するコード
  const handleTestSend = (type, value) => {
    try {
      let requestData = {};
      const msg = "テスト";
      if(type === "email"){
        requestData = {
          path: "/send_email",
          body: JSON.stringify({
            address: value,
            subject: 'デマンド監視システムテストメール',
            message: msg,
          }),
        };
      }else{
        alert("送信タイプが未定義です");
        return
      }

      axios.post(apiEndpoint, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        // レスポンスのステータスコードに応じて処理
        if (response.data.statusCode === 200) {
          alert("テスト送信成功しました");
        } else {
          const body = JSON.parse(response.data.body);
          alert("テスト送信失敗しました エラー:" + body.message);
        }
      }).catch((error) => {
        console.error("Error during login:", error);
        alert("テスト送信失敗しました エラー:" + error.message);
      });
    } catch (error) {
      console.error("Error during login:", error);
      alert("テスト送信失敗しました エラー:" + error.message);
    }
  };

// 更新ボタンがクリックされたときの処理
const handleRegister = async () => {

  const userConfirmed = window.confirm('この内容で登録しますか？');
    if (!userConfirmed) {
      return; // ユーザーが「いいえ」を選択した場合は何もしない
    }
  
  //どれか通知するなら通知時間帯チェック
  if (sms_notice || mail_notice){
    if(selectedPeriod === '1'){
      //空の場合
      if(!time_from || !time_to){
        alert("通知時間帯が空です。入力してください。");
        return;
      }
      // 開始と終了が同じ場合
      if(time_from.replace(/^0+/, '') === time_to.replace(/^0+/, '')){
        alert("通知時間帯の開始と終了は別の時間を入力してください。");
        return;
      }
    }
  }

  //SMS通知する場合
  if (sms_notice){
    if(!phoneNumber){
      alert("電話番号を入力してください");
      return;
    }
  }

  //メール通知する場合
  if (mail_notice){
    if(!email && !email2){
      alert("メールアドレスを入力してください");
      return;
    }
  }

  // 時間の形式に変換する関数
  const formatTime = (hours) => {
    // "hh:mm:ss" 形式に変換
    return `${hours}:00:00`;
  };

  try {
    const params = {
      NOTICE_TIME_FROM:time_from ? formatTime(time_from):time_from,
      NOTICE_TIME_TO:time_to ? formatTime(time_to):time_to,
      NOTICE_SMS_FLG:sms_notice ? 1:0,
      NOTICE_PERIOD_FLG:selectedPeriod==='0' ? 0:1,
      NOTICE_MAIL_FLG:mail_notice ? 1:0,
      NOTICE_MAIL_ADDRESS1:email,
      NOTICE_MAIL_ADDRESS2:email2,
      UPDATE_USER_ID:login_user_id,
    }

    const requestData = {
      path: '/update_notice',
      body: JSON.stringify({
        id: companyId,
        params: params,
        authCode:authCode,
        phoneNumber:phoneNumber,
      }),
    };

    // Lambda 関数を呼び出す
    const response = await axios.post(apiEndpoint, requestData);

    if (response && response.data && response.data.statusCode === 200) {
      // レスポンスをログに出力
      console.log('Lambda 関数のレスポンス:', response.data);
      alert("更新完了しました");
      handleEditClick(true);
      fetchData();//再検索


      // データの再読み込みなど、更新後の処理を追加することができます
    } else {
      const body = JSON.parse(response.data.body);
      alert(body.message);
    }
  } catch (error) {
    console.error('Error calling Lambda function:', error);
  }
};
  
  return (
    <React.Fragment>
      <Title>通知設定</Title>
        <div className="box" style={{ border: '1px solid black', borderRadius: '5px', position: 'relative', marginTop: '2em', width:'700px' }}>
          <h3 style={{ position: 'absolute', right: '0', left: '0', top: '-1.7em', marginLeft: '1em' }}>
            <span style={{ padding: '0 .5em', background: '#FFF' }}>通知時間帯</span>
          </h3>
          <div className="inner" style={{ padding: '1.2em .5em .5em', display: 'inline-block' , whiteSpace: 'nowrap'}}>
            <RadioGroup
              value={selectedPeriod}
              onChange={togglePeriod}
              row
              style={{ display: 'inline-block' }} // 追加
            >
              <FormControlLabel
                value='0'
                control={<Radio color="primary" />}
                label="いつでも"
                disabled={!isEditing}
              />
              <FormControlLabel
                value='1'
                control={<Radio color="primary" />}
                label="時間指定"
                disabled={!isEditing }
              />
            </RadioGroup>
            <select 
              id='START_HOUR'
              style={{ height: '35px', fontSize: '16px' ,width:'60px', display: 'inline-block'}}
              disabled={!isEditing || selectedPeriod === '0'}
              onChange={(e) => {handleChangeStartHour(e)}}
              value={time_from}
            >
              <option value="">--</option>
                {[...Array(24).keys()].map(hour => (
                  <option key={hour} value={hour}>{hour}</option>
                ))}
            </select>
            <InputLabel shrink htmlFor="start-date" style={{ display: 'inline-block', marginLeft: '0.5em' }}>
              時から
            </InputLabel>
            <select 
              id='END_HOUR'
              style={{ height: '35px', fontSize: '16px' ,width:'60px', display: 'inline-block'}}
              disabled={!isEditing|| selectedPeriod === '0'}
              onChange={(e) => {handleChangeEndHour(e)}}
              value={time_to}
            >
              <option value="">--</option>
                {[...Array(24).keys()].map(hour => (
                  <option key={hour} value={hour}>{hour}</option>
                ))}
            </select>
            <InputLabel shrink htmlFor="end-date" style={{ display: 'inline-block', marginLeft: '0.5em' }}>
              時まで
            </InputLabel>
            <Typography component="p" variant="h6" style={{ color: 'red'}}>
              ※上記時間帯の範囲でデマンド状態（注意/警告）を通知先に通知します。
            </Typography>
          </div>
        </div>

        <div className="box" style={{ border: '1px solid black', borderRadius: '5px', position: 'relative', marginTop: '2em',width:'880px' }}>
          <h3 style={{ position: 'absolute', right: '0', left: '0', top: '-1.7em', marginLeft: '1em' }}>
            <span style={{ padding: '0 .5em', background: '#FFF' }}>SMS</span>
          </h3>
          <div className="inner" style={{ padding: '1.2em .5em .5em', display: 'inline-block' , whiteSpace: 'nowrap'}}>
            <input type="checkbox" disabled={!isEditing} checked={sms_notice} onChange={(e) => setSmsNotice(e.target.checked)} style={{ display: 'inline-block'}}/>
            <Typography component="p" variant="h6" style={{ display: 'inline-block', marginRight:'20px'}}>
              SMS通知する
            </Typography>
            <input
              type="tel"
              pattern="[0-9]*"
              style={{ height: '40px', fontSize: '16px' ,display: 'inline-block', marginRight:'20px'}}
              placeholder="電話番号を入力"
              value={phoneNumber}
              onChange={(e) => {
                const cleanedInput = handlePhoneNumberChange(e);
                // stateを更新
                setPhoneNumber(cleanedInput);
              }}
              disabled={!isEditing}
            />
            <Button
              variant="contained"
              style={{ backgroundColor: '#ccc', color: 'black' , marginRight:'20px'}}
              onClick={handleAuthCodeSend}
              disabled={!isEditing}
            >
              <SmsIcon/>認証コード発行
            </Button>
            <input
              pattern="[0-9]*"
              style={{ height: '40px', fontSize: '16px' ,display: 'inline-block', marginRight:'20px'}}
              placeholder="認証コードを入力"
              onChange={(e) => {
                setAuthCode(e.target.value);
              }}
              disabled={!isEditing}
              value={authCode}
            />
            {sms_auth_status_text === '認証済' ? (
              <Typography component="p" variant="h6" style={{ display: 'inline-block', marginRight: '20px' }}>
                {sms_auth_status_text}
                <CheckIcon color="success" />
              </Typography>
            ) : (
              <Typography component="p" variant="h6" style={{ display: 'inline-block', marginRight: '20px' }}>
                {sms_auth_status_text}
                </Typography>
            )}
          </div>
        </div>
        <div className="box" style={{ border: '1px solid black', borderRadius: '5px', position: 'relative', marginTop: '2em',width:'700px' }}>
          <h3 style={{ position: 'absolute', right: '0', left: '0', top: '-1.7em', marginLeft: '1em' }}>
            <span style={{ padding: '0 .5em', background: '#FFF' }}>Email</span>
          </h3>
          <div className="inner" style={{ padding: '1.2em .5em .5em', display: 'inline-block' , whiteSpace: 'nowrap'}}>
            <input type="checkbox" disabled={!isEditing} checked={mail_notice} onChange={(e) => setMailNotice(e.target.checked)} style={{ display: 'inline-block'}}/>
            <Typography component="p" variant="h6" style={{ display: 'inline-block', marginRight:'20px'}}>
              Email通知する
            </Typography>
            <input
              type="text"
              style={{ width:'300px', height: '40px', fontSize: '16px' , marginRight:'20px'}}
              placeholder="メールアドレスを入力"
              value={email}
              onChange={(e) => {
                const result = handleEmailChange(e);
                setEmail(result);
              }}
              disabled={!isEditing}
             />
             <Button
              variant="contained"
              style={{ backgroundColor: '#ccc', color: 'black' , marginRight:'20px'}}
              onClick={handleEmailTestSend}
             >
              <EmailIcon/>テスト送信
            </Button>
          </div>
          <div className="inner" style={{ padding: '1.2em 11.1em .5em', display: 'inline-block' , whiteSpace: 'nowrap'}}>
            <input
              type="text"
              style={{ width:'300px',height: '40px', fontSize: '16px' , marginRight:'20px'}}
              placeholder="メールアドレスを入力"
              value={email2}
              onChange={(e) => {
                const result = handleEmailChange(e);
                setEmail2(result);
              }}
              disabled={!isEditing}
            />
            <Button
              variant="contained"
              style={{ backgroundColor: '#ccc', color: 'black' }}
              onClick={handleEmailTestSend2}
            >
              <EmailIcon/>テスト送信
            </Button>
          </div>
        </div>

        <div style={{  position: 'left',marginTop: '2em' }}>
          <Button
            variant="contained"
            color={'primary'}
            style={{ height: '50px' ,marginRight:'50px'}} // ボタンの高さを設定
            onClick={() => handleEditClick(!isEditing)}
          >
            {isEditing ? '編集をやめる' : '編集'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ height: '50px' }} // ボタンの高さを設定
            disabled={!isEditing}
            onClick={handleRegister}
           >
            登録
          </Button>
          
        </div>
      
    </React.Fragment>
  );
}

Notice.propTypes = {
  companyId: PropTypes.String,
  login_user_id:PropTypes.String,
};