import React, { useState, useRef, useCallback } from 'react';
import Title from '../home/Title';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import TablePagination from '@mui/material/TablePagination';
import CSVReader from 'react-csv-reader';
import axios from 'axios';
import PropTypes from 'prop-types';

export default function Demo({onDemoClick}) {

  const [tableData, setTableData] = useState([]);
  const [key, setKey] = useState(0); // 追加: キーを変更して再レンダリングをトリガーする
  const csvReaderRef = useRef(null);
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(20); // 1ページあたりの行数

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleCSVRead = (data) => {
    if (data.length > 0) {
      // 2行目以降のデータをセット
      setTableData(data.slice(1));
    }
  };

  const handleClear = useCallback(() => {
    // データをクリア
    setTableData([]);
    // キーを変更して新しいCSVReaderインスタンスを作成
    setKey((prevKey) => prevKey + 1);
  }, []);

  const handleDemoStart = async () => {
    try {
      // 以下のようにmeter_idを取得して渡す
      const id = tableData.length > 0 ? tableData[0][0] : null;

      onDemoClick(id,'リアルタイム監視'); // Chartを表示する関数を呼び出す

      let prevWaitTime = 0;
      for (const row of tableData) {
        let progress_time = parseFloat(row[6]);
        if (progress_time == 0){
          progress_time = 1800
        }
        const reset_flg = parseFloat(row[7]);
        if(reset_flg == 1){
          prevWaitTime = 0;
        }
        const waitTime = progress_time - prevWaitTime;
        
        // 一定時間待機
        await delay(waitTime * 1000);

        prevWaitTime = progress_time;
  
        // ここに非同期処理を追加
        const jsonData = {
          meter_id: row[0],
          get_date: row[1],
          get_time: row[2],
          now_power: row[3] ? parseInt(row[3]) : null,
          now_goal_power: row[4] ? parseInt(row[4]): null,
          prediction_power: row[5] ? parseInt(row[5]): null,
          progress_time: parseInt(row[6]),
          reset_flg: parseInt(row[7]),
        };
  
        const requestData = {
          path: '/monitor',
          body: JSON.stringify({ data: [jsonData] }),
        };
  
        try {
          const response = await axios.post(apiEndpoint, requestData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!(response && response.data && response.data.statusCode === 200)) {

            const body = JSON.parse(response.data.body);
            alert("エラーが発生しました。 エラー内容:" + body.message);
            onDemoClick(id,'管理者メニュー');
            return;
          }          
        } catch (error) {
          alert('Error:', error);
          // エラーが発生したらループを抜けるためにthrowを使用
          throw error;
        }
      }
      alert('デモが完了しました。');
    } catch (error) {
      alert('エラーが発生しました:', error);
      return;
    }
  };


  return (
    <React.Fragment>
      <Title>デマンド監視デモ</Title>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CSVReader
              onFileLoaded={handleCSVRead}
              parserOptions={{ skipEmptyLines: true }}
              key={key} // キーを設定して再レンダリングをトリガー
              ref={csvReaderRef}
            />
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: '10px' }}
              onClick={handleClear}
            >
              クリア
            </Button>
          </div>
          {/* CSVが読み込まれた後に1行目のデータを表示 */}
          {tableData.length > 0 && (
            <React.Fragment>
              <p>
                <Button
                  variant="contained"
                  style={{ color: 'primary' }}
                  onClick={handleDemoStart}
                >
                  デモ開始
                </Button>
              </p>
              
              <div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>管理ID</TableCell>
                        <TableCell>取得日</TableCell>
                        <TableCell>取得時刻</TableCell>
                        <TableCell>現在電力</TableCell>
                        <TableCell>現在目標電力</TableCell>
                        <TableCell>予測電力</TableCell>
                        <TableCell>経過時間(秒)</TableCell>
                        <TableCell>リセットフラグ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* 2行目以降のデータを表示 */}
                      {tableData.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell>{rowIndex + 1}</TableCell>
                          {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
  );
}

// prop-typesを設定
Demo.propTypes = {
  onDemoClick: PropTypes.func,
};