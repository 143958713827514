import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RegisterModal from './RegisterModal';
import './Orders.css';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress'

const meter_list_header = [
  {label:'計器ID',minWidth:150},
  {label:'設置場所',minWidth:150},
  {label:'デマンド状況',minWidth:120},
  {label:'直近超過発生',minWidth:150},
  {label:'現在電力(kW)',minWidth:100},
  {label:'予測電力(kW)',minWidth:100},
  {label:'目標電力(kW)',minWidth:100},
  {label:'契約電力(kW)',minWidth:100},
];

const meter_list_header_kenshin = [
  {label:'計器ID',minWidth:150},
  {label:'設置場所',minWidth:150},
];

const meter_list_header_admin = [
  {label:'管理ID',minWidth:150},
  {label:'計器ID',minWidth:150},
  {label:'設置場所',minWidth:150},
  {label:'権限',minWidth:100},
  {label:'デマンド状況',minWidth:120},
  {label:'直近超過発生',minWidth:150},
  {label:'現在電力(kW)',minWidth:100},
  {label:'予測電力(kW)',minWidth:100},
  {label:'目標電力(kW)',minWidth:100},
  {label:'契約電力(kW)',minWidth:100},
  {label:'MACアドレス',minWidth:150},
  {label:'ゲートウェイ名',minWidth:170},
  {label:'会社名',minWidth:170},
];

const gateway_list_header = [
  {label:'ゲートウェイID',minWidth:150},
  {label:'ゲートウェイ名',minWidth:170},
  {label:'MACアドレス',minWidth:150},
  {label:'会社名',minWidth:170},
];

const user_list_header = [
  {label:'ユーザーID',minWidth:150},
  {label:'ユーザー名',minWidth:170},
  {label:'メールアドレス',minWidth:170},
  {label:'パスワード',minWidth:170},
  {label:'権限',minWidth:100},
  {label:'会社名',minWidth:170},
];

const getSortKey = (key) => {
  switch (key) {
    case '管理ID':
      return 'METER_ID';
    case '計器ID':
      return 'SERIAL_NO';
    case '設置場所':
      return 'PLACE';
    case 'デマンド状況':
      return 'STATUS';
    case '契約電力(kW)':
      return 'CONTRACT_POWER';
    case '目標電力(kW)':
      return 'GOAL_POWER';
      case '予測電力(kW)':
        return 'PREDICTION_POWER';
    case '現在電力(kW)':
      return 'NOW_POWER';
    case 'ゲートウェイID':
      return 'GATEWAY_ID';
    case 'ゲートウェイ名':
      return 'GATEWAY_NAME';
    case 'MACアドレス':
      return 'MAC_ADDRESS';
    case 'ユーザーID':
      return 'USER_ID';
    case 'ユーザー名':
      return 'USER_NAME';
    case 'メールアドレス':
      return 'MAIL_ADDRESS';
    case 'パスワード':
      return 'PASSWORD';
    case '権限':
      return 'PRIVILEGE_FLG';
    case '会社名':
      return 'COMPANY_NAME';
    case '直近超過発生':
      return 'OVER_DT';
    default:
      return '';
  }
}

const getCellContent = (header, row) => {
  let status_text;  // ここで変数宣言
  let cellStyle = {}; // スタイルを格納する変数

  let privilege_text = "";
  let over_dt_text = "";

  switch (header) {
    case '管理ID':
      return row.METER_ID;
    case '計器ID':
      return row.SERIAL_NO;
    case '設置場所':
      return row.PLACE;
    case 'デマンド状況':
      status_text = ''
      if (row.STATUS == 1)
        status_text = ' 警告 '
      else if(row.STATUS == 2)
        status_text = ' 超過 '
      else if(row.STATUS == 0)
      status_text = ' 平常 '
      // 超過の場合は背景色を赤に、警告の場合はオレンジに
      cellStyle.backgroundColor = status_text === ' 超過 ' ? 'red' : status_text === ' 警告 ' ? 'orange' : '';
      cellStyle.display = 'inline-block';

      return (
        <div style={{ ...cellStyle, whiteSpace: 'pre' }}>
          {status_text}
        </div>
      );
      
    case '契約電力(kW)':
      return row.CONTRACT_POWER;
    case '目標電力(kW)':
      return row.GOAL_POWER;
      case '予測電力(kW)':
        return row.PREDICTION_POWER!==-1 ? row.PREDICTION_POWER:'';
    case '現在電力(kW)':
      return row.NOW_POWER!==-1 ? row.NOW_POWER:'';
    case 'ゲートウェイID':
      return row.GATEWAY_ID;
    case 'ゲートウェイ名':
      return row.GATEWAY_NAME;
    case 'MACアドレス':
      return row.MAC_ADDRESS;
    case 'ユーザーID':
      return row.USER_ID;
    case 'ユーザー名':
      return row.USER_NAME;
    case 'メールアドレス':
      return row.MAIL_ADDRESS;
    case 'パスワード':
      return row.PASSWORD;
    case '権限':
      if (row.PRIVILEGE_FLG === 0 || row.PRIVILEGE_FLG === '0')
        privilege_text = 'デマンドプラン'
      else if(row.PRIVILEGE_FLG === 1 || row.PRIVILEGE_FLG === '1')
        privilege_text = '管理者'
      else if(row.PRIVILEGE_FLG === 2 || row.PRIVILEGE_FLG === '2')
        privilege_text = '検針プラン'
      return privilege_text;
    case '会社名':
      return row.COMPANY_NAME;
    case '直近超過発生':
      if(row.OVER_DT){
        over_dt_text = formatDate(row.OVER_DT);
      }    
    return over_dt_text;
    default:
      return '';
  }
};

const fetchDataFromServer = async (company_id,info_flg,privilegeFlg,reg_flg,filter_flg,filter_value,selectedPlan) => {
  try {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    let requestData = {};
    if(!privilegeFlg){
      privilegeFlg = '0';
    }
    
    if(reg_flg === '0'){
      requestData = {
        path: '/get_info',
        body: JSON.stringify({
          company_id: company_id,
          info_flg: info_flg,
          privilege_flg:privilegeFlg,
          chart_flg:'0',
          selected_plan:selectedPlan,
        }),
      };
    }else if(reg_flg === '1'){
      requestData = {
        path: '/get_filter',
        body: JSON.stringify({
          company_id: company_id,
          info_flg: info_flg,
          privilege_flg:privilegeFlg,
          filter_flg:filter_flg,
          filter_value:filter_value,
        }),
      };
    }else if(reg_flg === '2'){
      requestData = {
        path: '/get_gw_company',
      };

    }
    
    const response = await axios.post(apiEndpoint, requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response && response.data && response.data.statusCode === 200) {
      console.log(response.data.body);
      return response.data.body; // レスポンスのデータを返す
    } else {
      console.error('Error in API response:', response.data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return null;
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(/\//g, '-').replace(',', '');
  return formattedDate;
};


export default function Orders({ onChartClick ,onHistoryClick, title,param,login_user_id,privilegeFlg,selectedPlan}) {
  const [header_rows, setHeaderRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [editingRow, setEditingRow] = useState(null);  // 編集中の行のデータ
  const [isModalOpen, setIsModalOpen] = useState(false);  // モーダルの表示状態
  // Orders コンポーネント内の useState の定義
  const [sortConfig, setSortConfig] = useState({ key: null, disp_key:null, direction: 'asc' });
  // 初期のfilter_list
  const initialFilterList = ["会社名", "計器ID"];
  // filter_listをstateとして保持
  const [filter_list, setFilterList] = useState(initialFilterList);
  const [company_list,setCompanyList] = useState([]);
  const [filterButtonDisabled, setFilterButtonDisabled] = useState(true);  // 絞り込み解除ボタンの使用可/不可
  const [rowsPerPage, setRowsPerPage] = React.useState(() => {
    // ローカルストレージから状態を取得する
    const storedrRowsPerPage = parseInt(localStorage.getItem('order_rows_per_page'));
    return storedrRowsPerPage || 10; // 状態がない場合はデフォルト値を使用
  });
  const [page, setPage] = useState(0); // 現在のページ
  const [isLoading, setIsLoading] = useState(true); // 読み込み中の状態を管理
  const [isFirst,setIsFirst] = useState(true);
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    
    fetchData('2','0',''); // 会社リスト作成

    if (privilegeFlg === 1 && selectedPlan === 'demand') {
      // 会社名を選んだ場合
      document.getElementById('COMPANY_LIST').style.display = 'inline-block';
      document.getElementById('SERIAL_NO').style.display = 'none';
    } else {
      // 計器IDを選んだ場合
      document.getElementById('COMPANY_LIST').style.display = 'none';
      document.getElementById('SERIAL_NO').style.display = 'inline-block';
    }
  }, []);

  useEffect(() => {
    fetchData('0','0',''); // 初回描画時にデータを取得する
    
  }, [title]);

  useEffect(() => {
    fetchData('0','0',''); // 初回描画時にデータを取得する

    if (privilegeFlg === 1 && selectedPlan === 'demand') {
      // 会社名を選んだ場合
      document.getElementById('COMPANY_LIST').style.display = 'inline-block';
      document.getElementById('SERIAL_NO').style.display = 'none';
    } else {
      // 計器IDを選んだ場合
      document.getElementById('COMPANY_LIST').style.display = 'none';
      document.getElementById('SERIAL_NO').style.display = 'inline-block';
    }
    
  }, [selectedPlan]);

  const fetchData = async (reg_flg,filter_flg,filter_value) => {
    try {
      setIsLoading(true); // 読み込み中の状態を true に設定
      // titleに基づいてinfo_flgを設定
      let currentInfoFlg = "0";
      if (title === "計器一覧") {
        currentInfoFlg = "0";
        setFilterButtonDisabled(true);
        if (privilegeFlg === 1 && selectedPlan === 'demand'){
          setHeaderRows(meter_list_header_admin);
          setFilterList(["会社名", "計器ID"]);
        }else if(privilegeFlg === 2 || selectedPlan === 'kensin'){
          setHeaderRows(meter_list_header_kenshin);
          setFilterList(["計器ID"]);
        }else{
          setHeaderRows(meter_list_header);
          setFilterList(["計器ID"]);
        }
      } else if (title === "GW一覧") {
        currentInfoFlg = "1";
        setHeaderRows(gateway_list_header);
        setFilterList(["会社名"]);
        document.getElementById('COMPANY_LIST').style.display = 'inline-block';
        document.getElementById('SERIAL_NO').style.display = 'none';
        setFilterButtonDisabled(true);
      } else if (title === "ユーザー一覧") {
        currentInfoFlg = "2";
        setHeaderRows(user_list_header);
        setFilterList(["会社名"]);
        document.getElementById('COMPANY_LIST').style.display = 'inline-block';
        document.getElementById('SERIAL_NO').style.display = 'none';
        setFilterButtonDisabled(true);
      }
      
      // ここでテーブルをクリア
      setRows([]);

      const data = await fetchDataFromServer(param, currentInfoFlg,privilegeFlg,reg_flg,filter_flg,filter_value,selectedPlan);
      if (data) {
        const dataArray = JSON.parse(data);
        if(reg_flg === '2'){
          setCompanyList(dataArray['COMPANY_LIST']);
        }else{
          setRows(dataArray);
        }
        setPage(0);
        
      }
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
    if(isFirst){
      await delay(1.3 * 1000);
      setIsFirst(false);
    }
    setIsLoading(false); // 読み込み中の状態を true に設定
  };

  // ソート関数の定義
  const handleSort = (key) => {
    // ソートキーを修正
    const sortKey = getSortKey(key);
    // クリックされた列と現在のソート状態に基づいて新しいソート状態を設定
    setSortConfig({
      key: sortKey,
      disp_key:key,
      direction: sortConfig.key === sortKey && sortConfig.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  // ソート状態の変更時に実行される useEffect
  useEffect(() => {
    const sortedData = getSortedData();
    setRows(sortedData);
  }, [sortConfig]);

    // ソートされたデータの取得関数
    const getSortedData = () => {
      if (!sortConfig.key) {
        return rows;
      }

      const sortedRows = [...rows].sort((a, b) => {
        console.log('a[sortConfig.key]:',a[sortConfig.key]);
        console.log('b[sortConfig.key]:',b[sortConfig.key]);
        
        let valueA;
        let valueB;
        if(a[sortConfig.key] !== 0){
          valueA = isNaN(a[sortConfig.key]) ? a[sortConfig.key] : Math.floor(parseFloat(a[sortConfig.key])) || undefined;
        }else{
          valueA = a[sortConfig.key];
        }

        if(b[sortConfig.key] !== 0){
          valueB = isNaN(b[sortConfig.key]) ? b[sortConfig.key] : Math.floor(parseFloat(b[sortConfig.key])) || undefined;
        }else{
          valueB = b[sortConfig.key];
        }

        console.log('valueA:',valueA);
        console.log('valueB:',valueB);

        // nullまたはundefinedを考慮して比較
        if (valueA === undefined && valueB === -1) return 0;
        if (valueB === undefined && valueA === -1) return 0;
        if (valueB === undefined) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        if (valueA === undefined) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (valueB === undefined) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }

        // 0 の場合を処理
        if (valueA === 0 && valueB === 0) return 0;

        // 通常の比較
        if (valueA < valueB) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });

      return sortedRows;
    };

  const handleFilterChange = () => {
    const filterType = document.getElementById('FILTER_LIST').value;
  
    if (filterType === '会社名') {
      // 会社名を選んだ場合
      document.getElementById('COMPANY_LIST').style.display = 'inline-block';
      document.getElementById('SERIAL_NO').style.display = 'none';
    } else if (filterType === '計器ID') {
      // 計器IDを選んだ場合
      document.getElementById('COMPANY_LIST').style.display = 'none';
      document.getElementById('SERIAL_NO').style.display = 'inline-block';
    }
  };

  const handleFilter = async () => {
    const filterType = document.getElementById('FILTER_LIST').value;
    let filter_flg = '0';
    let filter_value = '';
  
    if (filterType === '会社名') {
      filter_flg = '1';
      filter_value = document.getElementById('COMPANY_LIST').value;
    } else if (filterType === '計器ID') {
      filter_flg = '2';
      filter_value = document.getElementById('SERIAL_NO').value;
    }

    //絞り込み項目が空の場合は全検索
    if (filter_value === '') {
      // fetchData を呼び出す
      await fetchData('0','0','');
      // 絞り込み解除ボタンを使用可に設定
      setFilterButtonDisabled(true);
    }else{
      // fetchData を呼び出す
      await fetchData('1',filter_flg,filter_value);
      // 絞り込み解除ボタンを使用可に設定
      setFilterButtonDisabled(false);
    }    
  };

  const handleFilterClear = async () => {
  
    // fetchData を呼び出す
    await fetchData('0','0','');

    // 絞り込み解除ボタンを使用不可に設定
    setFilterButtonDisabled(true);
  };

  const toggleDemands = (id) => {
    onChartClick(id); // Chartを表示する関数を呼び出す
  };

  const toggleHistory = (id) => {
    onHistoryClick(id);
  }

  const handleModalClose = () => {
    // モーダルが閉じられたら編集中の行のデータをリセット
    setEditingRow(null);
    // モーダルを閉じる
    setIsModalOpen(false);
    // データを再検索
    fetchData('0','0','');
  };

  const toggleDelete = async (row) => {
    // 確認ダイアログを表示し、ユーザーが「はい」を選択した場合にのみ削除を実行
    const userConfirmed = window.confirm('削除しますか？');
    if (!userConfirmed) {
      return; // ユーザーが「いいえ」を選択した場合は何もしない
    }
  
    try {
      const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
      const reg_flg = '2';
      let id = '';
      // titleに基づいてinfo_flgを設定
      let currentInfoFlg = "0";
      if (title === "計器一覧") {
        currentInfoFlg = "0";
        id = row.METER_ID;
      } else if (title === "GW一覧") {
        currentInfoFlg = "1";
        id = row.GATEWAY_ID;
      } else if (title === "ユーザー一覧") {
        currentInfoFlg = "2";
        id = row.USER_ID;
      }
  
      const requestData = {
        path: '/update_info',
        body: JSON.stringify({
          id: id,
          info_flg: currentInfoFlg, // 現在のinfo_flgがスコープ内にあることを仮定しています
          reg_flg: reg_flg,
          privilege_flg:privilegeFlg,
        }),
      };
  
      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response && response.data && response.data.statusCode === 200) {
        // オプションで成功時の挙動を処理したり、通知を表示したりすることができます
        alert('アイテムが正常に削除されました。');
        // UIに変更を反映させるためにデータを再取得します
        fetchData('0','0','');
      } else {
        console.error('APIレスポンスでエラーが発生しました:', response.data);
        // オプションでエラーメッセージを表示したり、エラーを処理したりすることができます
      }
    } catch (error) {
      console.error('削除リクエストの実行中にエラーが発生しました:', error);
      // オプションでエラーメッセージを表示したり、エラーを処理したりすることができます
    }
  };

  // ページ変更時のハンドラ関数
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // 1ページあたりの行数変更時のハンドラ関数
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('order_rows_per_page', parseInt(event.target.value, 10));
    setPage(0); // 表示ページをリセット
  };

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <div className="order-content">
        <Typography component="p" variant="h6">
          <select 
            id='FILTER_LIST'
            style={{ height: '35px', fontSize: '16px' , marginRight:'20px', pointerEvents: isLoading ? 'none' : '' }}
            onChange={() => handleFilterChange()}
          >
            {filter_list.map((filter,index) => {
              return <option key={index}>{filter}</option>;
            })}
          </select>
          <input
            id='SERIAL_NO'
            type="text"
            style={{ height: '35px', fontSize: '16px' , marginRight:'20px', pointerEvents: isLoading ? 'none' : '' }}
          />
          <select 
            style={{ height: '35px', fontSize: '16px' , marginRight:'20px' , pointerEvents: isLoading ? 'none' : ''}} 
            id='COMPANY_LIST'
            >
            <option value=""></option> {/* 最初の要素として空のoption要素を追加 */}
            {company_list.map((company) => {
              return <option key={company.COMPANY_ID} value={company.COMPANY_ID}>{company.COMPANY_NAME}</option>;
            })}
          </select>
          <Button 
            variant="contained"
            color="primary"
            style={{marginRight: '20px',pointerEvents: isLoading ? 'none' : ''}}
            onClick={() => {
              handleFilter();
            }}
          >
            <SearchIcon />絞り込み
          </Button>
          <Button 
            variant="contained"
            color="primary"
            onClick={() => {
              handleFilterClear();
            }}
            disabled={filterButtonDisabled}  // 使用不可の場合は disabled を設定
          >
            <ClearIcon />絞り込み解除
          </Button>
          {privilegeFlg === 1 && selectedPlan === 'demand' && (
              <>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#primary',width: 'auto',marginLeft: '80px' , pointerEvents: isLoading ? 'none' : ''}}
                  onClick={() => {
                    // ここで新しい行のデータを設定
                    setEditingRow(null);
                    // モーダルを開く
                    setIsModalOpen(true);
                  }}
                >
                  <AddIcon/>新規
                </Button>
                  
              </>
            )}
        </Typography>
      </div>      
      <div style={{ marginLeft: '20px'}}>
      </div>
      {isLoading ? ( // 読み込み中の場合、ローディング画面を表示
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
          <CircularProgress /> Loading...
        </div>
      ) : (
        <Paper sx={{ width: '100%' ,overflow:'hidden'}}>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {title === "計器一覧" && (
                    <>
                      {(privilegeFlg === 1 || privilegeFlg === 0) && selectedPlan === 'demand' && (
                          <>
                            <TableCell style={{ width:90}}></TableCell>
                          </>
                        )}
                      <TableCell></TableCell>
                    </>
                  )}
                  {header_rows.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{cursor: 'pointer'}}
                      onClick={() => handleSort(header.label)}
                    >
                      {header.label}
                      {sortConfig.disp_key === header.label && (
                        <span style={{ marginLeft: '4px' }}>
                          {sortConfig.direction === 'asc' ? '▲' : '▼'}
                        </span>
                      )}
                    </TableCell>
                  ))}
                    {(privilegeFlg === 1 || privilegeFlg === 0) && selectedPlan === 'demand' && (
                          <>
                            <TableCell></TableCell>
                          </>
                    )}
                  
                    <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                    {title === "計器一覧" && (
                      <>
                        {(privilegeFlg === 1 || privilegeFlg === 0) && selectedPlan === 'demand' && (
                          <>
                            <TableCell>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: '#ccc', color: 'black' ,width: '120px'}}
                                onClick={() => {
                                  // ここで新しい行のデータを設定
                                  toggleDemands(row.METER_ID);
                                }}
                              >
                                リアルタイム
                              </Button>
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: '#ccc', color: 'black',width: '65px' }}
                            onClick={() => toggleHistory(row.METER_ID)}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </>
                    )}
                    {header_rows.map((header, columnIndex) => (
                      <TableCell key={columnIndex} style={{minWidth:header.minWidth,overflow:'hidden'}}>
                        {getCellContent(header.label, row)}
                      </TableCell>
                  ))}
                  <TableCell>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#ccc', color: 'black',width: '65px' }}
                        onClick={() => {
                          // ここで新しい行のデータを設定
                          setEditingRow(row);
                          // モーダルを開く
                          setIsModalOpen(true);
                        }}
                      >
                        編集
                      </Button>
                    </TableCell>
                    
                    {privilegeFlg === 1 && selectedPlan === 'demand' && (
                      <>
                      <TableCell>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#ccc', color: 'black',width: '60px' }}
                          onClick={() => toggleDelete(row)}
                        >
                          <DeleteIcon/>
                        </Button>
                      </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
                
              </TableBody>
            </Table>
            </TableContainer>
          <TablePagination
              component="div"
              count={rows.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
              labelRowsPerPage="表示数"
            />
        </Paper>
       )}
      {/* RegisterModalをモーダルとして表示 */}
      {/* 編集用のモーダル */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* RegisterModal コンポーネントに必要な props を渡す */}
            <RegisterModal 
              closeModal={handleModalClose}
              rowData={editingRow}
              title={title}
              login_user_id={login_user_id}
              privilegeFlg={privilegeFlg}
              selectedPlan={selectedPlan}
            />
          </div>
        </div>
      )}
      
      
    </React.Fragment>
  );
}

// prop-typesを設定
Orders.propTypes = {
  onChartClick: PropTypes.func,
  onHistoryClick: PropTypes.func,
  title: PropTypes.string,
  param: PropTypes.string,
  login_user_id:PropTypes.string,
  privilegeFlg:PropTypes.string,
  selectedPlan:PropTypes.string,
};
