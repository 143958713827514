import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // 'Switch' ではなく 'Routes'
import PropTypes from 'prop-types'; // PropTypesをimport

import LoginForm from './components/LoginForm';
import Home from './components/home/Home';

// 1. 状態を管理するためのContextを作成
const MyContext = React.createContext();

// 2. Context Providerコンポーネントを作成し、状態を提供
const MyContextProvider = ({ children }) => {
  // initialStateを定義する
  const initialState = {}; // 例えば空のオブジェクトとして初期化

  const [myState, setMyState] = React.useState(initialState);

  return (
    <MyContext.Provider value={{ myState, setMyState }}>
      {children}
    </MyContext.Provider>
  );
};

// propsの型を定義
MyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ReactDOM.render(
  <Router>
    <MyContextProvider> {/* Context Providerを追加 */}
      <Routes> {/* 'Switch' の代わりに 'Routes' を使用 */}
        <Route path="/" element={<LoginForm />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </MyContextProvider>
  </Router>,
  document.getElementById('root')
);